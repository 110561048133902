import { TagFirmwareState } from '../types'
import TagFirmware from '../../models/tagFirmware'
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from 'models/apiResponse'

//Get an Firmware from a Vue component by calling this.$store.state.firmware.list;

const state: TagFirmwareState = {
    list: [],
}

const getters: GetterTree<TagFirmwareState, any> = {
    getTagFirmwareList: state => () => {
        return state.list;
    },
    getTagFirmware: state => (Id: number) => {
        return state.list.find(f => f.tag_firmware_id == Id);
    }
}

const mutations: MutationTree<TagFirmwareState> = {
    setTagFirmwareList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((tagFirmware: TagFirmware, index) => {
            Vue.set(state.list, index, tagFirmware);
        });
    }
}

const actions: ActionTree<TagFirmwareState, any> = {
    async fetchTagFirmware({ commit, state, dispatch }, id) {
        if (id && !state.list.find(f => f.tag_firmware_id == id)) {
            await dispatch('fetchTagFirmwares');
        }
    },
    async fetchTagFirmwares({ commit, state }) {
        const url = Vue.IdentityConfig.legacyApiUri + "/remote/tagfirmware";
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "no-cache"
        };
        await fetch(url, options)
            .then(response => response.json() as Promise<ApiResponse<TagFirmware[]>>)
            .then(data => {
                if (data.result && data.result.length > 0) {
                    const firmwares = [];
                    data.result.forEach(f => {
                        firmwares.push(new TagFirmware(f));
                    })
                    commit('setTagFirmwareList', firmwares);
                }
                if (data.errors) {
                    data.errors.forEach(e => Vue.Logger.LogError(e.message));
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}