import FirmwarePackage from 'models/mesh/firmwarePackage'
import TagFirmware from 'models/tagFirmware';
import TagBootloader from 'models/tagBootloader';
import parseISO from 'date-fns/parseISO'
import OTAComponent from 'models/mesh/ota/component';
import DatetimeConverter from '../../utils/datetimeConverter';
import ManufacturingModel from 'models/manufacturing/manufacturingModel';
import { EnumDto } from '../../store/types';

export class Manufacturer {
    id: string;
    name: string;

    constructor(manufacturer: any = null) {
        if (manufacturer) {
            this.id = manufacturer.id;
            this.name = manufacturer.name;
        }
    }
}

export enum DeviceType {
    Gateway = 0,
    Relay = 1,
    Tag = 2,
    DMR = 3
}

export enum JobStatus {
    Pending = 0,
    Started = 1,
    Finished = 2
}

export enum JobItemStage {
    PCBLabelPrinted = 0,
    Programmed = 1,
    Assembled = 2,
    Labelled = 3,
    QAed = 4,
    Packaged = 5,
    Associated = 6,
    BatteryTest = 7,
    TagRangeTest = 8
}

export class StageSummary {
    stage: EnumDto<JobItemStage>;
    count: number;
    passed: number;
    failed: number;
}

export enum EventStatus {
    Pending = 0,
    Passed = 1,
    Failed = 2
}

export class JobItemStageStatus {
    stage: EnumDto<JobItemStage>;
    status: EnumDto<EventStatus>;
    statusDate: string;

    constructor(jobItemStageStatus: JobItemStageStatus) {
        this.stage = jobItemStageStatus.stage;
        this.status = jobItemStageStatus.status;
        this.statusDate = jobItemStageStatus.statusDate;
    }
}

export default class Job {
    jobNo: string;
    deviceType: string;
    manufacturer: Manufacturer;
    purchaseOrderNo: string;
    modelNo: string;
    status: EnumDto<EventStatus>;
    quantity: number;
    boxLimit: number;
    createdDate: string;
    startedDate: string;
    finishedDate: string;
    firmwarePackage?: FirmwarePackage;
    tagFirmware?: TagFirmware;
    tagBootloader?: TagBootloader;

    tagFirmwareId?: number;
    tagBootloaderId?: number;

    model: ManufacturingModel;

    components: OTAComponent[];

    comments: string;
    shortComments: string;
    stageSummary: StageSummary[];
    ianaTimeZoneName: string;

    manufacturerName: string;
    firmwareId: number;
    exp_manufacturer: string;
    exp_firmware: string;
    exp_pcb_barcode_printed_passed: string;
    exp_pcb_barcode_printed_failed: string;
    exp_programmed_passed: string;
    exp_programmed_failed: string;
    exp_associated_passed: string;
    exp_associated_failed: string;
    exp_battery_tested_passed: string;
    exp_battery_tested_failed: string;
    exp_assembled_passed: string;
    exp_assembled_failed: string;
    exp_labelled_passed: string;
    exp_labelled_failed: string;
    exp_qa_passed: string;
    exp_qa_failed: string;
    exp_range_tested_passed: string;
    exp_range_tested_failed: string;
    exp_packaged_passed: string;
    exp_packaged_failed: string;
    exp_dispatched_passed: string;
    exp_dispatched_failed: string;
    exp_failed_total: number;

    showComponents: boolean = false;

    get firmware_version(): string {
        return this.tagFirmware ? this.tagFirmware.version : (this.firmwarePackage ? this.firmwarePackage.id.toString() : '');
    }

    get firmware_details(): string {
        return this.firmwarePackage ?
            this.firmwarePackage.concat_version : this.tagFirmware ?
                `TF: ${this.tagFirmware.version}, SD: ${this.tagFirmware.soft_device_name}, BL: ${this.tagBootloader?.version ?? 'No Bootloader'}` : 'Gateway firmware';
    }

    get sort_firmware_package(): number {
        if (!this.firmwarePackage) return 0;
            var e: number = 0;
            [this.firmwarePackage.application.version,
                this.firmwarePackage.soft_device.version,
                this.firmwarePackage.bootloader.version].reverse().forEach(function (v, i) {
                return e += +v << (i * 8);
            });
           return e;
    }

    get pcb_barcode_printed(): StageSummary {
        return this.stageSummary ? this.stageSummary.find(s => s.stage.key === JobItemStage.PCBLabelPrinted) : null;
    }
    get programmed(): StageSummary {
        return this.stageSummary ? this.stageSummary.find(s => s.stage.key === JobItemStage.Programmed) : null;
    }
    get associated(): StageSummary {
        return this.stageSummary ? this.stageSummary.find(s => s.stage.key === JobItemStage.Associated) : null;
    }
    get battery_tested(): StageSummary {
        return this.stageSummary ? this.stageSummary.find(s => s.stage.key === JobItemStage.BatteryTest) : null;
    }
    get assembled(): StageSummary {
        return this.stageSummary ? this.stageSummary.find(s => s.stage.key === JobItemStage.Assembled) : null;
    }
    get labelled(): StageSummary {
        return this.stageSummary ? this.stageSummary.find(s => s.stage.key === JobItemStage.Labelled) : null;
    }
    get qa(): StageSummary {
        return this.stageSummary ? this.stageSummary.find(s => s.stage.key === JobItemStage.QAed) : null;
    }
    get range_tested(): StageSummary {
        return this.stageSummary ? this.stageSummary.find(s => s.stage.key === JobItemStage.TagRangeTest) : null;
    }
    get packaged(): StageSummary {
        return this.stageSummary ? this.stageSummary.find(s => s.stage.key === JobItemStage.Packaged) : null;
    }
    get dispatched(): StageSummary {
        return null;
    }
    get failed_total(): number {
        let total = 0;
        if (this.stageSummary) {
            if (this.stageSummary.find(s => s.stage.key === JobItemStage.PCBLabelPrinted))
                total += this.stageSummary.find(s => s.stage.key === JobItemStage.PCBLabelPrinted).failed;
            if (this.stageSummary.find(s => s.stage.key === JobItemStage.Programmed))
                total += this.stageSummary.find(s => s.stage.key === JobItemStage.Programmed).failed;
            if (this.stageSummary.find(s => s.stage.key === JobItemStage.Associated))
                total += this.stageSummary.find(s => s.stage.key === JobItemStage.Associated).failed;
            if (this.stageSummary.find(s => s.stage.key === JobItemStage.BatteryTest))
                total += this.stageSummary.find(s => s.stage.key === JobItemStage.BatteryTest).failed;
            if (this.stageSummary.find(s => s.stage.key === JobItemStage.Assembled))
                total += this.stageSummary.find(s => s.stage.key === JobItemStage.Assembled).failed;
            if (this.stageSummary.find(s => s.stage.key === JobItemStage.Labelled))
                total += this.stageSummary.find(s => s.stage.key === JobItemStage.Labelled).failed;
            if (this.stageSummary.find(s => s.stage.key === JobItemStage.QAed))
                total += this.stageSummary.find(s => s.stage.key === JobItemStage.QAed).failed;
            if (this.stageSummary.find(s => s.stage.key === JobItemStage.TagRangeTest))
                total += this.stageSummary.find(s => s.stage.key === JobItemStage.TagRangeTest).failed;
            if (this.stageSummary.find(s => s.stage.key === JobItemStage.Packaged))
                total += this.stageSummary.find(s => s.stage.key === JobItemStage.Packaged).failed;
        }
        return total;
    }

    get created_date(): string {
        return this.formatDate(this.createdDate, 'yyyy-MM-dd HH:mm');
    }

    get started_date(): string {
        return this.formatDate(this.startedDate, 'yyyy-MM-dd');
    }

    get finished_date(): string {
        return this.formatDate(this.finishedDate, 'yyyy-MM-dd');
    }

    formatDate(date: string, format: string): string {
        return DatetimeConverter.ToSelectedTimezoneDatetime(date, this.ianaTimeZoneName, format);
    }

    constructor(searchResult: Job) {

        let DeviceTypeDisplay: { [index: number]: string } = {};
        DeviceTypeDisplay[DeviceType.Tag] = "Tag";
        DeviceTypeDisplay[DeviceType.Relay] = "Relay";
        DeviceTypeDisplay[DeviceType.Gateway] = "Gateway";

        this.model = searchResult.model;

        if (this.model) {
            this.modelNo = this.model.modelNo;
            this.deviceType = DeviceTypeDisplay[this.model.deviceType.key];
        }

        this.jobNo = searchResult.jobNo;
        
        this.manufacturer = searchResult.manufacturer;
        this.purchaseOrderNo = searchResult.purchaseOrderNo;
        
        this.status = searchResult.status;
        this.quantity = searchResult.quantity;
        this.boxLimit = searchResult.boxLimit;
        this.createdDate = searchResult.createdDate;
        this.startedDate = searchResult.startedDate;
        this.finishedDate = searchResult.finishedDate;

        this.tagFirmwareId = searchResult.tagFirmwareId;
        this.tagBootloaderId = searchResult.tagBootloaderId;

        this.comments = searchResult.comments;
        this.shortComments = this.comments ? this.comments.length > 8 ? this.comments.substr(0, 8) + '...' : this.comments : ''
        this.stageSummary = searchResult.stageSummary;


        this.firmwareId = this.firmwarePackage ? this.firmwarePackage.id : this.tagFirmware ? this.tagFirmware.tag_firmware_id : 0;
        this.exp_pcb_barcode_printed_passed = this.pcb_barcode_printed ? this.pcb_barcode_printed.passed.toString() : '';
        this.exp_pcb_barcode_printed_failed = this.pcb_barcode_printed ? this.pcb_barcode_printed.failed.toString() : '';
        this.exp_programmed_passed = this.programmed ? this.programmed.passed.toString() : '';
        this.exp_programmed_failed = this.programmed ? this.programmed.failed.toString() : '';
        this.exp_associated_passed = this.associated ? this.associated.passed.toString() : '';
        this.exp_associated_failed = this.associated ? this.associated.failed.toString() : '';
        this.exp_battery_tested_passed = this.battery_tested ? this.battery_tested.passed.toString() : '';
        this.exp_battery_tested_failed = this.battery_tested ? this.battery_tested.failed.toString() : '';
        this.exp_assembled_passed = this.assembled ? this.assembled.passed.toString() : '';
        this.exp_assembled_failed = this.assembled ? this.assembled.failed.toString() : '';
        this.exp_labelled_passed = this.labelled ? this.labelled.passed.toString() : '';
        this.exp_labelled_failed = this.labelled ? this.labelled.failed.toString() : '';
        this.exp_qa_passed = this.qa ? this.qa.passed.toString() : '';
        this.exp_qa_failed = this.qa ? this.qa.failed.toString() : '';
        this.exp_range_tested_passed = this.range_tested ? this.range_tested.passed.toString() : '';
        this.exp_range_tested_failed = this.range_tested ? this.range_tested.failed.toString() : '';
        this.exp_packaged_passed = this.packaged ? this.packaged.passed.toString() : '';
        this.exp_packaged_failed = this.packaged ? this.packaged.failed.toString() : '';
        this.exp_dispatched_passed = this.dispatched ? this.dispatched.passed.toString() : '';
        this.exp_dispatched_failed = this.dispatched ? this.dispatched.failed.toString() : '';
        this.exp_failed_total = this.failed_total;

        if (this.manufacturer) {
            this.manufacturerName = this.manufacturer ? this.manufacturer.name : '';
            this.exp_manufacturer = this.manufacturer.name;
        }
        if (searchResult.components) {
            this.components = searchResult.components.map(component => {
                return new OTAComponent(component);
            });
        }
    }
}
