import Vue from "vue";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
export default class LoggerService {
    private development: boolean;

    constructor(environment: string) {
        this.development = environment != 'production' ? true : false;
    }

    public Log(message, saveToInsights: Boolean = true) {
        if (this.development) {
            console.log(message);
        }
        else {
            if (saveToInsights) {
                if (typeof message === 'string')
                    Vue.appInsights.trackEvent({ name: message });
                else
                    Vue.appInsights.trackEvent({ name: JSON.stringify(message) });
            }
        }
    }

    public LogInfo(message, saveToInsights: Boolean = true) {
        if (this.development) {
            console.info(message);
        }
        else {
            if (saveToInsights) {
                if (typeof message === 'string')
                    Vue.appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Information }, null, SeverityLevel.Information);
                else
                    Vue.appInsights.trackTrace({ message: JSON.stringify(message), severityLevel: SeverityLevel.Information }, null, SeverityLevel.Information);
            }
        }
    }

    public LogDebug(message, saveToInsights: Boolean = true) {
        if (this.development) {
            console.debug(message);
        }
        else {
            if (saveToInsights) {
                if (typeof message === 'string')
                    Vue.appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Verbose }, null, SeverityLevel.Information);
                else
                    Vue.appInsights.trackTrace({ message: JSON.stringify(message), severityLevel: SeverityLevel.Verbose }, null, SeverityLevel.Information);
            }
        }
    }

    public LogError(error, saveToInsights: Boolean = true) {
        if (this.development) {
            console.error(error);
        }
        else {
            if (saveToInsights) {
                if (error instanceof Error) {
                    Vue.appInsights.trackException({ exception: error });
                }
                else if (typeof error === 'string') {
                    Vue.appInsights.trackException({ exception: new Error(error) });
                }
                else {
                    Vue.appInsights.trackException({ exception: new Error(JSON.stringify(error)) });
                }
            }
        }
    }

    public LogWarning(message, saveToInsights: Boolean = true) {
        if (this.development)
            console.warn(message);
        else {
            if (saveToInsights) {
                if (typeof message === 'string')
                    Vue.appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Warning }, null, SeverityLevel.Warning);
                else
                    Vue.appInsights.trackTrace({ message: JSON.stringify(message), severityLevel: SeverityLevel.Warning }, null, SeverityLevel.Warning);
            }
        }
    }

    public error(message?: any, ...optionalParams: any[]) {
        if (this.development) {
            console.error.apply(console, Array.from(arguments));
        }
        else {
            var params = this.formatOptionalParams(optionalParams);
            Vue.appInsights.trackException({ exception: new Error(message + params) });
        }
    }

    public info(message?: any, ...optionalParams: any[]) {
        if (this.development) {
            console.info.apply(console, Array.from(arguments));
        }
        else {
            var params = this.formatOptionalParams(optionalParams);
            Vue.appInsights.trackTrace({ message: message + params, severityLevel: SeverityLevel.Information }, null, SeverityLevel.Information);
        }
    }

    public warn(message?: any, ...optionalParams: any[]) {
        if (this.development)
            console.warn.apply(console, Array.from(arguments));
        else {
            var params = this.formatOptionalParams(optionalParams);
            Vue.appInsights.trackTrace({ message: message + params, severityLevel: SeverityLevel.Warning }, null, SeverityLevel.Warning);
        }
    }

    public debug(message?: any, ...optionalParams: any[]) {
        if (this.development)
            console.debug.apply(console, Array.from(arguments));
        else {
            var params = this.formatOptionalParams(optionalParams);
            Vue.appInsights.trackTrace({ message: message + params, severityLevel: SeverityLevel.Verbose }, null, SeverityLevel.Verbose);
        }
    }

    private formatOptionalParams(args) {
        var params = [];
        for (var _len = args.length, _key = 0; _key < _len; _key++) {
            params.push(JSON.stringify(args[_key]));
        }
        return params.length > 0 ? ' ' + params.join(' ') : '';
    }
}