import { store } from '../store/index'

export default class Common {

    constructor() {

    }

    // Returns the human-readable file size for an arbitrary, 64-bit file size
    // The default format is "0.### XB", e.g. "4.2 KB" or "1.434 GB"
    static ToBytesReadable(bytes: number): string {
        // Get absolute value
        let absolute_i: number = (bytes < 0 ? -bytes : bytes);
        // Determine the suffix and readable value
        let suffix: string;
        let readable: number;
        if (absolute_i >= 0x1000000000000000) // Exabyte
        {
            suffix = "EB";
            readable = (bytes >>> 50);
        }
        else if (absolute_i >= 0x4000000000000) // Petabyte
        {
            suffix = "PB";
            readable = (bytes >>> 40);
        }
        else if (absolute_i >= 0x10000000000) // Terabyte
        {
            suffix = "TB";
            readable = (bytes >>> 30);
        }
        else if (absolute_i >= 0x40000000) // Gigabyte
        {
            suffix = "GB";
            readable = (bytes >>> 20);
        }
        else if (absolute_i >= 0x100000) // Megabyte
        {
            suffix = "MB";
            readable = (bytes >>> 10);
        }
        else if (absolute_i >= 0x400) // Kilobyte
        {
            suffix = "KB";
            readable = bytes;
        }
        else {
            //bytes = bytes.toFixed(3);
            return parseFloat(bytes.toFixed(3)).toString() + " bytes"; // Byte
        }

        // Divide by 1024 to get fractional value
        readable = (readable / 1024);

        // Return formatted number with suffix
        return parseFloat(readable.toFixed(3)).toString() + " " + suffix;
    }

    static FormatDate(input: any): string {
        if (input == null) return null;
        if (!(input instanceof Date))
            input = new Date(input)

        return input.toISOString().substring(0, 19).replace('T', ' ');
    }

    static BuildApiUrlWithParams(url: string, params: any) {
        var esc = encodeURIComponent;
        var query = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
        return url + "?" + query;
    }

    static formatMinToddhhmm(input: number) {
        var seconds = Number(input * 60);
        var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor(seconds % (3600 * 24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);

        var formatted = d + "d " + ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2);

        return formatted;
    }

    // This is javascripts best attempt at creating a 
    static newSessionId() {
        return 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        }).toUpperCase();
    }

    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static showRemoteModal() {
        store.commit('showRemoteModal', true);
    }

    static strLimit(input, limit) {
        if (!input || input.length <= limit) {
            return input;
        }
        return input.substring(0, limit) + '...';
    }

    static iccidstrLimit(input, limit) {
        if (!input || input.length <= limit) {
            return input;
        }
        return '*' + input.substring(input.length - limit);
    }

    static toCamel(s) {
        return s.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
        });
    };

    static toSnake(s) {
        return s.replace(/[A-Z]/g, ($1, index) => {
            return index === 0 ? $1.toLowerCase() : '_' + $1.toLowerCase();
        }).replace(/([.][_])/g, ($1) => {
            return $1.replace('_', '');
        });
    };

    static isArray(a) {
        return Array.isArray(a);
    };

    static isObject(o) {
        return o === Object(o) && !Common.isArray(o) && typeof o !== 'function';
    };

    static keysToCamel(obj) {
        if (Common.isObject(obj)) {
            const newObj = {};

            var props = Object.keys(obj);
            for (var i = 0; i < props.length; i++) {
                var prop = props[i];
                newObj[Common.toCamel(prop)] = Common.keysToCamel(obj[prop]);
            };

            return newObj;
        } else if (Common.isArray(obj)) {
            return obj.map((i) => {
                return Common.keysToCamel(i);
            });
        }
        return obj;
    };

    static isNumeric(s: string) {
        if (!s)
            return false;
        var result = /^\d+(\.\d+)?$/.test(s.trim());
        return result;
    }
}

export class Base64Binary {
        static _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

        /* will return a  Uint8Array type */
        static decodeArrayBuffer(input: string) {
            const bytes = (input.length / 4) * 3;
            const ab = new ArrayBuffer(bytes);
            this.decode(input, ab);

            return ab;
        }

        private static removePaddingChars(input: string) {
            const lkey = this._keyStr.indexOf(input.charAt(input.length - 1));
            if (lkey === 64) {
                return input.substring(0, input.length - 1);
            }
            return input;
        }

        private static decode(input: string, arrayBuffer: ArrayBuffer) {
            //get last chars to see if are valid
            input = this.removePaddingChars(input);
            input = this.removePaddingChars(input);

            const bytes = parseInt(''+((input.length / 4) * 3), 10);

            let uarray: Uint8Array;
            let chr1, chr2, chr3;
            let enc1, enc2, enc3, enc4;
            let i = 0;
            let j = 0;

            if (arrayBuffer)
                uarray = new Uint8Array(arrayBuffer);
            else
                uarray = new Uint8Array(bytes);

            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

            for (i = 0; i < bytes; i += 3) {
                //get the 3 octects in 4 ascii chars
                enc1 = this._keyStr.indexOf(input.charAt(j++));
                enc2 = this._keyStr.indexOf(input.charAt(j++));
                enc3 = this._keyStr.indexOf(input.charAt(j++));
                enc4 = this._keyStr.indexOf(input.charAt(j++));

                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;

                uarray[i] = chr1;
                if (enc3 !== 64) uarray[i + 1] = chr2;
                if (enc4 !== 64) uarray[i + 2] = chr3;
            }

            return uarray;
        }
    }