export default class IdentityConfig {
    authority: string;
    environment: string;
    postLogoutRedirectUri: string;
    redirectUri: string;    
    appID: string;
    appSecret?: string;
    sealedAppID?: string;

    baseApiUri: string;
    legacyApiPath: string;
    manufacturingApiPath: string;

    get legacyApiUri(): string {
        return this.baseApiUri + this.legacyApiPath;
    }

    get manufacturingApiUri(): string {
        return this.baseApiUri + this.manufacturingApiPath;
    }

    constructor(identity: IdentityConfig = null) {
        if (identity) {
            this.authority = identity.authority;
            this.environment = identity.environment;
            this.postLogoutRedirectUri = identity.postLogoutRedirectUri;
            this.redirectUri = identity.redirectUri;
            this.appID = identity.appID;
            this.sealedAppID = identity.sealedAppID;
            this.baseApiUri = identity.baseApiUri;
            this.legacyApiPath = identity.legacyApiPath;
            this.manufacturingApiPath = identity.manufacturingApiPath;
        }
    }
}