import { SoftDeviceState } from '../types'
import SoftDevice from 'models/softDevice'
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from 'models/apiResponse'

//Get a SoftDevice from a Vue component by calling this.$store.state.softDevice.list;

const state: SoftDeviceState = {
    list: [],
}

const getters: GetterTree<SoftDeviceState, any> = {
    getSoftDeviceList: state => () => {
        return state.list;
    }
}

const mutations: MutationTree<SoftDeviceState> = {
    setSoftDeviceList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((softDevice: SoftDevice, index) => {
            Vue.set(state.list, index, softDevice);
        });
    }
}

const actions: ActionTree<SoftDeviceState, any> = {
    async fetchSoftDevices({ commit, state }) {
        const url = Vue.IdentityConfig.legacyApiUri + "/remote/softdevices/list";
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "no-cache"
        };
        await fetch(url, options)
            .then(response => response.json() as Promise<ApiResponse<SoftDevice[]>>)
            .then(data => {
                if (data.result && data.result.length > 0) {
                    const softDevices = [];
                    data.result.forEach(f => {
                        softDevices.push(new SoftDevice(f));
                    })
                    commit('setSoftDeviceList', softDevices);
                }
                if (data.errors) {
                    data.errors.forEach(e => Vue.Logger.LogError(e.message));
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}