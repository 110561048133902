import { GetterTree, MutationTree, ActionTree } from 'vuex'
import get from 'lodash/get'

interface SortState {
    sortDirection: string;
    sortColumn: string;
    sortColumns: Array<string>;
}


const getters: GetterTree<SortState, any> = {
    getSort: state => () => {
        return state.sortColumn + '_' + state.sortDirection;
    },
    sortColumns: state => () => {
        return state.sortColumns;
    },
    sortColumn: state => () => {
        return state.sortColumn;
    }
}

const mutations: MutationTree<SortState> = {
    sort(state, column) {
        if (column === state.sortColumn) {
            state.sortDirection = state.sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
            state.sortDirection = 'asc';
        }

        state.sortColumn = column;
        state.sortColumns = [column];
    },
    sortMany(state, columns) {
        let columnA = columns[0];
        if (columnA === state.sortColumn) {
            state.sortDirection = state.sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
            state.sortDirection = 'asc';
        }

        state.sortColumn = columnA;
        state.sortColumns = columns;
    },
    sortResults(state, data: any[]) {

        if (state.sortColumns.length == 0)
            state.sortColumns.push(state.sortColumn);

        if (state.sortDirection === 'asc') {
            data.sort((a, b) => {
                for (const key of state.sortColumns) {
                    const aKey = get(a, key);
                    const bKey = get(b, key);
                    if (aKey < bKey || (bKey == undefined && aKey !== undefined)) return -1;
                    if (aKey > bKey || (bKey !== undefined && aKey == undefined)) return 1;
                }
                return 0;
            })
        }
        else {
            data.sort((a, b) => {
                for (const key of state.sortColumns) {
                    const aKey = get(a, key);
                    const bKey = get(b, key);
                    if (aKey > bKey || (bKey !== undefined && aKey == undefined)) return -1;
                    if (aKey < bKey || (bKey == undefined && aKey !== undefined)) return 1;
                }
                return 0;
            })
        }
    },
    setSortColumn(state, value: string) {
        state.sortColumn = value;
        state.sortColumns = [value];
    },
    setSortColumns(state, value: string[]) {
        state.sortColumns = value;
    },
    setSortDirection(state, value: string) {
        state.sortDirection = value;
    }
}

const actions: ActionTree<SortState, any> = {
    setSortColumns({ commit }, value) {
        commit('setSortColumns', value);
    },
    setSortColumn({ commit }, value) {
        commit('setSortColumn', value);
    },
    setSortDirection({ commit }, value) {
        commit('setSortDirection', value);
    }
}

class State implements SortState {
    sortDirection = "asc";
    sortColumns: string[] = new Array<string>();
    sortColumn = "";
}

const state = new State();

export default {
    state,
    mutations,
    getters,
    actions
}