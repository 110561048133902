import CryptoJS from "crypto-js/core.js";
import sha256 from "crypto-js/sha256.js";
import Base64 from "crypto-js/enc-base64.js";
import Utf8 from "crypto-js/enc-utf8.js";

import { Logger } from "./Logger";


const UUID_V4_TEMPLATE = "10000000-1000-4000-8000-100000000000";

/**
 * @internal
 */
export class CryptoUtils {
    private static _randomWord(): number {
        return CryptoJS.lib.WordArray.random(1).words[0];
    }

    /**
     * Generates RFC4122 version 4 guid
     */
    public static generateUUIDv4(): string {
        const uuid = UUID_V4_TEMPLATE.replace(/[018]/g, c =>
            (+c ^ CryptoUtils._randomWord() & 15 >> +c / 4).toString(16),
        );
        return uuid.replace(/-/g, "");
    }

    public static generateSealedAppId(app_id: string, app_secret: string): string {
        const ts_hm = new Date();
        const seal = app_id + ";" + ts_hm.toISOString().substring(0, 16) + ";" + app_secret;
        const hashed = sha256(seal);
        const sealedAppId = app_id + ";1;" + Base64.stringify(hashed);
        
        return sealedAppId;
    }
}
