import MessageReader from "utils/MessageReader";

export class ClientBase {
    Timestamp: Date;
    VpnName: string;
    ClientName: string;
    Type: string;
    ID: number;
    NameUserName: string;
    Read(reader: MessageReader, eventType: string, token: string) {
        this.Timestamp = reader.ReadNextDateTime(" INFO ");
        var routerName = reader.ReadNext(" CLIENT: ");
        reader.ReadNext(eventType + ": ");

        this.VpnName = reader.ReadNext(" ");
        this.ClientName = reader.ReadNext(" ");
        this.Type = reader.ReadNext(" (");
        this.ID = reader.ReadNextInt32(") ");
        this.NameUserName = reader.ReadNext(token);
    }
}
export class ClientConnect extends ClientBase {
    readonly EventTypeName: string = "CLIENT_CLIENT_CONNECT"
    OriginalClientUsername: string;
    WebSessionId: string;
    ApplianceIP: string;
    AppliancePort: number;
    ClientIP: string;
    ClientPort: number;
    SDKVersion: string;
    Platform: string;
    SslVersion: string;
    SslCipher: string;
    APIUser: string;
    AuthScheme: string;
    AuthorizationGroup: string;
    ClientProfile: string;
    ACLProfile: string;
    SSLDowngradedToPlainText: string;
    SSLRevocation: string;
    constructor(reader: MessageReader) {
        super();
        super.Read(reader, this.EventTypeName, " OriginalClientUsername(");
        this.OriginalClientUsername = reader.ReadNext(") WebSessionId (");
        this.WebSessionId = reader.ReadNext(") connected to ");
        this.ApplianceIP = reader.ReadNext(":");
        this.AppliancePort = reader.ReadNextInt32(" from ");
        this.ClientIP = reader.ReadNext(":");
        this.ClientPort = reader.ReadNextInt32(" version(");
        this.SDKVersion = reader.ReadNext(") platform(");
        this.Platform = reader.ReadNext(") SslVersion(");
        this.SslVersion = reader.ReadNext(") SslCipher(");
        this.SslCipher = reader.ReadNext(") APIuser(");
        this.APIUser = reader.ReadNext(") authScheme(");
        this.AuthScheme = reader.ReadNext(") authorizationGroup(");
        this.AuthorizationGroup = reader.ReadNext(") clientProfile(");
        this.ClientProfile = reader.ReadNext(") ACLProfile(");
        this.ACLProfile = reader.ReadNext(") SSLDowngradedToPlainText(");
        this.SSLDowngradedToPlainText = reader.ReadNext(")");
    }
}
export class ClientDisconnect extends ClientBase {
    readonly EventTypeName: string = "CLIENT_CLIENT_DISCONNECT"
    WebSessionId: string;
    DisconnectReason: string;

    DpStatistics: any;//DataplaneStatistics;
    ConnStatistics: any;//ConnectionStatistics;
    ClientIP: string;
    ClientPort: number;
    State: string;
    ZipStatistics: any;//CompressionStatistics;
    WebStatistics: any;//WebDataplaneStatistics;

    SslVersion: string;
    SslCipher: string;

    constructor(reader: MessageReader) {
        super();
        super.Read(reader, this.EventTypeName, " WebSessionId (");

        this.WebSessionId = reader.ReadNext(") reason(");
        this.DisconnectReason = reader.ReadNext(") final statistics - dp(");
        this.DpStatistics = {};//new DataplaneStatistics();
        this.DpStatistics.ControlMsgsReceived = reader.ReadNextInt32(", ");
        this.DpStatistics.ControlMsgsDelivered = reader.ReadNextInt32(", ");
        this.DpStatistics.TopicMsgsReceived = reader.ReadNextInt32(", ");
        this.DpStatistics.TopicMsgsDelivered = reader.ReadNextInt32(", ");
        this.DpStatistics.TotalMsgsReceived = reader.ReadNextInt32(", ");
        this.DpStatistics.TotalMsgsDelivered = reader.ReadNextInt32(", ");
        this.DpStatistics.ControlBytesReceived = reader.ReadNextInt32(", ");
        this.DpStatistics.ControlBytesDelivered = reader.ReadNextInt32(", ");
        this.DpStatistics.TopicBytesReceived = reader.ReadNextInt32(", ");
        this.DpStatistics.TopicBytesDelivered = reader.ReadNextInt32(", ");
        this.DpStatistics.TotalBytesReceived = reader.ReadNextInt32(", ");
        this.DpStatistics.TotalBytesDelivered = reader.ReadNextInt32(", ");
        this.DpStatistics.CurrentMsgRateIngress = reader.ReadNextInt32(", ");
        this.DpStatistics.CurrentMsgRateEgress = reader.ReadNextInt32(", ");
        this.DpStatistics.AvgMsgRateIngress = reader.ReadNextInt32(", ");
        this.DpStatistics.AvgMsgRateEgress = reader.ReadNextInt32(", ");
        this.DpStatistics.DeniedDuplicateClients = reader.ReadNextInt32(", ");
        this.DpStatistics.DiscardsNoSubscriptionMatch = reader.ReadNextInt32(", ");
        this.DpStatistics.DiscardsTopicParseError = reader.ReadNextInt32(", ");
        this.DpStatistics.DiscardsParseError = reader.ReadNextInt32(", ");
        this.DpStatistics.DiscardsMsgTooBig = reader.ReadNextInt32(", ");
        this.DpStatistics.DiscardsTransmitCongestion = reader.ReadNextInt32(") conn(");

        this.ConnStatistics = {};//new ConnectionStatistics();
        this.ConnStatistics.ReceivedQueueBytes = reader.ReadNextInt32(", ");
        this.ConnStatistics.SendQueueBytes = reader.ReadNextInt32(", ");
        this.ClientIP = reader.ReadNext(":");
        this.ClientPort = reader.ReadNextInt32(", ");
        this.State = reader.ReadNext(", ");
        this.ConnStatistics.ReceivedOutOfOrder = reader.ReadNextInt32(", ");
        this.ConnStatistics.FastRetransmit = reader.ReadNextInt32(", ");
        this.ConnStatistics.TimedRetransmit = reader.ReadNextInt32(") zip(");

        this.ZipStatistics = {};//new CompressionStatistics();
        this.ZipStatistics.CompressedBytesReceived = reader.ReadNextInt32(", ");
        this.ZipStatistics.CompressedBytesDelivered = reader.ReadNextInt32(", ");
        this.ZipStatistics.UncompressedBytesReceived = reader.ReadNextInt32(", ");
        this.ZipStatistics.UncompressedBytesDelivered = reader.ReadNextInt32(", ");
        this.ZipStatistics.CompressionRatioIngress = reader.ReadNextDecimal(", ");
        this.ZipStatistics.CompressionRatioEgress = reader.ReadNextDecimal(", ");
        this.ZipStatistics.CurrentCompressedByteRateIngress = reader.ReadNextInt32(", ");
        this.ZipStatistics.CurrentCompressedByteRateEgress = reader.ReadNextInt32(", ");
        this.ZipStatistics.CurrentUncompressedByteRateIngress = reader.ReadNextInt32(", ");
        this.ZipStatistics.CurrentUncompressedByteRateEgress = reader.ReadNextInt32(", ");
        this.ZipStatistics.AvgCompressedByteRateIngress = reader.ReadNextInt32(", ");
        this.ZipStatistics.AvgCompressedByteRateEgress = reader.ReadNextInt32(", ");
        this.ZipStatistics.AvgUncompressedByteRateIngress = reader.ReadNextInt32(", ");
        this.ZipStatistics.AvgUncompressedByteRateEgress = reader.ReadNextInt32(") web(");

        this.WebStatistics = {};//new WebDataplaneStatistics();
        this.WebStatistics.WebMsgsReceived = reader.ReadNextInt32(", ");
        this.WebStatistics.WebMsgsDelivered = reader.ReadNextInt32(", ");
        this.WebStatistics.WebBytesReceived = reader.ReadNextInt32(", ");
        this.WebStatistics.WebBytesDelivered = reader.ReadNextInt32(", ");
        this.WebStatistics.WebOutOfOrder = reader.ReadNextInt32(", ");
        this.WebStatistics.WebFastRetransmit = reader.ReadNextInt32(", ");
        this.WebStatistics.WebTimedRetransmit = reader.ReadNextInt32("), SslVersion(");

        this.SslVersion = reader.ReadNext("), SslCipher(");
        this.SslCipher = reader.ReadNext(")");
    }
}



export class ClientConnectMQTT extends ClientBase {
    readonly EventTypeName: string = "CLIENT_CLIENT_CONNECT_MQTT"
    OriginalClientUsername: string;
    ClientId: string;
    ApplianceIP: string;
    AppliancePort: number;
    ClientIP: string;
    ClientPort: number;
    SslVersion: string;
    SslCipher: string;
    AuthScheme: string;
    Clean: number;
    Will: number;
    SslRevocation: string;
    constructor(reader: MessageReader) {
        super();
        super.Read(reader, this.EventTypeName, " OriginalClientUsername(");
        this.OriginalClientUsername = reader.ReadNext(") ClientId (");
        this.ClientId = reader.ReadNext(") connected to ");
        this.ApplianceIP = reader.ReadNext(":");
        this.AppliancePort = reader.ReadNextInt32(" from ");
        this.ClientIP = reader.ReadNext(":");

        this.ClientPort = reader.ReadNextInt32(" SslVersion(");
        this.SslVersion = reader.ReadNext(") SslCipher(");


        this.SslCipher = reader.ReadNext(") authScheme(");
        this.AuthScheme = reader.ReadNext(") Clean(");
        this.Clean = reader.ReadNextInt32(") Will(");
        this.Will = reader.ReadNextInt32(") SslRevocation(");

        this.SslRevocation = reader.ReadNext(")");
    }
}

export class ClientDisconnectMQTT extends ClientBase {
    readonly EventTypeName: string = "CLIENT_CLIENT_DISCONNECT_MQTT"
    DisconnectReason: string;
    ClientId: string;
    DpStatistics: any;//DataplaneStatistics;
    ConnStatistics: any;//ConnectionStatistics;
    ClientIP: string;
    ClientPort: number;
    State: string;
    MQTTStatistics: any;

    SslVersion: string;
    SslCipher: string;

    WillSent: number;

    constructor(reader: MessageReader) {
        super();
        super.Read(reader, this.EventTypeName, " ClientId (");

        this.ClientId = reader.ReadNext(") reason(");
        this.DisconnectReason = reader.ReadNext(") final statistics - dp(");
        this.DpStatistics = {};//new DataplaneStatistics();
        this.DpStatistics.ControlMsgsReceived = reader.ReadNextInt32(", ");
        this.DpStatistics.ControlMsgsDelivered = reader.ReadNextInt32(", ");
        this.DpStatistics.TopicMsgsReceived = reader.ReadNextInt32(", ");
        this.DpStatistics.TopicMsgsDelivered = reader.ReadNextInt32(", ");
        this.DpStatistics.TotalMsgsReceived = reader.ReadNextInt32(", ");
        this.DpStatistics.TotalMsgsDelivered = reader.ReadNextInt32(", ");
        this.DpStatistics.ControlBytesReceived = reader.ReadNextInt32(", ");
        this.DpStatistics.ControlBytesDelivered = reader.ReadNextInt32(", ");
        this.DpStatistics.TopicBytesReceived = reader.ReadNextInt32(", ");
        this.DpStatistics.TopicBytesDelivered = reader.ReadNextInt32(", ");
        this.DpStatistics.TotalBytesReceived = reader.ReadNextInt32(", ");
        this.DpStatistics.TotalBytesDelivered = reader.ReadNextInt32(", ");
        this.DpStatistics.CurrentMsgRateIngress = reader.ReadNextInt32(", ");
        this.DpStatistics.CurrentMsgRateEgress = reader.ReadNextInt32(", ");
        this.DpStatistics.AvgMsgRateIngress = reader.ReadNextInt32(", ");
        this.DpStatistics.AvgMsgRateEgress = reader.ReadNextInt32(", ");
        this.DpStatistics.DeniedDuplicateClients = reader.ReadNextInt32(", ");
        this.DpStatistics.DiscardsNoSubscriptionMatch = reader.ReadNextInt32(", ");
        this.DpStatistics.DiscardsTopicParseError = reader.ReadNextInt32(", ");
        this.DpStatistics.DiscardsParseError = reader.ReadNextInt32(", ");
        this.DpStatistics.DiscardsMsgTooBig = reader.ReadNextInt32(", ");
        this.DpStatistics.DiscardsTransmitCongestion = reader.ReadNextInt32(") conn(");

        this.ConnStatistics = {};//new ConnectionStatistics();
        this.ConnStatistics.ReceivedQueueBytes = reader.ReadNextInt32(", ");
        this.ConnStatistics.SendQueueBytes = reader.ReadNextInt32(", ");
        this.ClientIP = reader.ReadNext(":");
        this.ClientPort = reader.ReadNextInt32(", ");
        this.State = reader.ReadNext(", ");
        this.ConnStatistics.ReceivedOutOfOrder = reader.ReadNextInt32(", ");
        this.ConnStatistics.FastRetransmit = reader.ReadNextInt32(", ");
        this.ConnStatistics.TimedRetransmit = reader.ReadNextInt32(") mqtt(");

        this.MQTTStatistics = {};
        this.MQTTStatistics.ConnectPacketsReceived= reader.ReadNextInt32(", ");
        this.MQTTStatistics.ConnAckPacketsSent= reader.ReadNextInt32(", ");
        this.MQTTStatistics.ConnAckErrorsSent= reader.ReadNextInt32(", ");
        this.MQTTStatistics.PublishReceivedResponses= reader.ReadNextInt32(", ");
        this.MQTTStatistics.PublishMessagePacketsSent= reader.ReadNextInt32(", ");
        this.MQTTStatistics.PublishAcknowledgementsReceived= reader.ReadNextInt32(", ");
        this.MQTTStatistics.PublishAcknowledgementsSent= reader.ReadNextInt32(", ");
        this.MQTTStatistics.PublishReceivedPacketsSent= reader.ReadNextInt32(", ");
        this.MQTTStatistics.PublishReleaseResponsesReceived= reader.ReadNextInt32(", ");
        this.MQTTStatistics.PublishCompleteResponsesSent= reader.ReadNextInt32(", ");
        this.MQTTStatistics.SubscribeToTopicsPacketsReceived= reader.ReadNextInt32(", ");
        this.MQTTStatistics.SubscribeAcknowledgementsSent= reader.ReadNextInt32(", ");
        this.MQTTStatistics.SubscribeAcknowldegementErrorsSent= reader.ReadNextInt32(", ");
        this.MQTTStatistics.UnsubscribeFromTopicPacketsReceived= reader.ReadNextInt32(", ");
        this.MQTTStatistics.UnsubscribeFromTopicPacketsSent= reader.ReadNextInt32(", ");
        this.MQTTStatistics.PingRequestsReceived= reader.ReadNextInt32(", ");
        this.MQTTStatistics.PingResponsesSent = reader.ReadNextInt32(", ");
        this.MQTTStatistics.DisconnectNotificationsReceived = reader.ReadNextInt32("), SslVersion(");


        this.SslVersion = reader.ReadNext("), SslCipher(");
        this.SslCipher = reader.ReadNext("), WillSent(");
        this.WillSent = reader.ReadNextInt32(")");
    }
}