export default class State {
    name: string;
    subregion: string;
    region: string;
    postal: string;
    country: string;

    constructor(state: State = null) {
        if (state) {
            this.name = state.name;
            this.region = state.region;
            this.subregion = state.subregion;
            this.postal = state.postal;
            this.country = state.country;
        }
    }
}