import { OUState, OU } from '../types'
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from 'models/apiResponse'

//Get an OU from a Vue component by calling this.$store.state.ou.list[ouid];

const state: OUState = {
    list: [],
    timestampMS: null
}

const getters: GetterTree<OUState, any> = {
    getOUList: state => () => {
        return state.list;
    },
    getOU: state => (ouShortName: string) => {
        return state.list.find(f => f.short_name == ouShortName);
    }
}

const mutations: MutationTree<OUState> = {
    setOUList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((ou: OU, index) => {
            Vue.set(state.list, index, ou);
        });
    },
    setOUTimestamp(state) {
        // 5 Mins
        const expiration = 5 * 60 * 1000;
        state.timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<OUState, any> = {
    async fetchOUs({ commit, state, dispatch }) {
        if (state.list.length == 0 || (state.timestampMS && new Date().getTime() > state.timestampMS)) {
            await dispatch('refreshOUs');
        }
    },
    async refreshOUs({ commit, state }) {
        const url = Vue.IdentityConfig.legacyApiUri + "/OUs";
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "no-cache"
        };
        await fetch(url, options)
            .then(response => response.json() as Promise<ApiResponse<OU>>)
            .then(data => {
                if (data.result) {
                    commit('setOUList', data.result);
                    commit('setOUTimestamp');
                }
                if (data.errors) {
                    data.errors.forEach(e => Vue.Logger.LogError(e.message));
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}