import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

export default class TagFirmware {
    tag_firmware_id: number;
    soft_device_id: string;
    soft_device_name: string;
    major_version: string;
    minor_version: string;
    description: string;
    tag_firmware_uri: string;
    soft_device_tag_firmware_uri: string;
    tag_firmware_sas_token: string;
    soft_device_tag_firmware_sas_token: string;
    created_by: string;
    created_date: string;
    deleted: boolean;
    deleted_by: string;
    deleted_date: string;
    model_id: number;
    model_no: string;
    tag_firmware_status: TagFirmwareStatusCode;
    status_history: TagFirmwareStatus[];
    showHistory: boolean = false;
    has_deployments: boolean = false;

    get version(): string {
        return this.major_version + '.' + this.minor_version;
    }

    get sortversion(): number {
        if (this.version) {
            var e: number = 0;
            this.version.split('.').reverse().forEach(function (v, i) {
                return e += +v << (i * 8);
            });
            return e;
        }
    }

    get tagFirmwareStatusCode() {
        return TagFirmwareStatusCode[this.tag_firmware_status];
    }

    constructor(tagFirmware: TagFirmware = null) {
        if (tagFirmware) {
            this.tag_firmware_id = tagFirmware.tag_firmware_id;
            this.soft_device_id = tagFirmware.soft_device_id;
            this.soft_device_name = tagFirmware.soft_device_name || 'None';
            this.major_version = tagFirmware.major_version;
            this.minor_version = tagFirmware.minor_version;
            this.description = tagFirmware.description || '';
            this.tag_firmware_uri = tagFirmware.tag_firmware_uri || '';
            this.tag_firmware_sas_token = tagFirmware.tag_firmware_sas_token;
            this.soft_device_tag_firmware_uri = tagFirmware.soft_device_tag_firmware_uri || '';
            this.soft_device_tag_firmware_sas_token = tagFirmware.soft_device_tag_firmware_sas_token;
            this.created_by = tagFirmware.created_by || '';
            this.created_date = format(parseISO(tagFirmware.created_date), 'yyyy-MM-dd HH:mm') || '';
            this.deleted = tagFirmware.deleted || false;
            this.deleted_by = tagFirmware.deleted_by || '';
            this.deleted_date = format(parseISO(tagFirmware.deleted_date), 'yyyy-MM-dd HH:mm') || '';
            this.model_id = tagFirmware.model_id;
            this.model_no = tagFirmware.model_no;
            this.tag_firmware_status = tagFirmware.tag_firmware_status;
            this.has_deployments = tagFirmware.has_deployments;
            if (tagFirmware.status_history) {
                this.status_history = new Array<TagFirmwareStatus>();
                tagFirmware.status_history.forEach(h => {
                    this.status_history.push(new TagFirmwareStatus(h));
                })
            }
        }
    }
}

export class TagFirmwareStatus {
    tag_firmware_id: number;
    tag_firmware_status_code_id: number;
    comment: string;
    created_by: string;
    created_date: string;

    get tagFirmwareStatusCode() {
        return TagFirmwareStatusCode[this.tag_firmware_status_code_id];
    }

    constructor(tagFirmwareStatus: TagFirmwareStatus) {
        this.tag_firmware_id = tagFirmwareStatus.tag_firmware_id;
        this.tag_firmware_status_code_id = tagFirmwareStatus.tag_firmware_status_code_id;
        this.comment = tagFirmwareStatus.comment;
        this.created_by = tagFirmwareStatus.created_by;
        this.created_date = format(parseISO(tagFirmwareStatus.created_date), 'yyyy-MM-dd HH:mm');
    }
}

export enum TagFirmwareStatusCode {
    "" = 0,
    "New" = 1,
    "Test Passed" = 2,
    "Test Failed" = 3,
    "Approved" = 4,
    "Retired" = 5
}