import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

export default class TagBootloader {
    tag_bootloader_id: number;
    major_version: string;
    minor_version: string;
    chipset_id: string;
    description: string;
    tag_bootloader_uri: string;
    tag_bootloader_sas_token: string;
    created_by: string;
    created_date: string;
    deleted: boolean;
    deleted_by: string;
    deleted_date: string;
    tag_bootloader_status: TagBootloaderStatusCode;
    status_history: TagBootloaderStatus[];
    showHistory: boolean = false;
    has_deployments: boolean = false;

    get version(): string {
        return this.major_version + '.' + this.minor_version;
    }

    get sortversion(): number {
        if (this.version) {
            var e: number = 0;
            this.version.split('.').reverse().forEach(function (v, i) {
                return e += +v << (i * 8);
            });
            return e;
        }
    }

    get tagBootloaderStatusCode() {
        return TagBootloaderStatusCode[this.tag_bootloader_status];
    }

    constructor(tagBootloader: TagBootloader = null) {
        if (tagBootloader) {
            this.tag_bootloader_id = tagBootloader.tag_bootloader_id;
            this.major_version = tagBootloader.major_version;
            this.minor_version = tagBootloader.minor_version;
            this.chipset_id = tagBootloader.chipset_id;
            this.description = tagBootloader.description || '';
            this.tag_bootloader_uri = tagBootloader.tag_bootloader_uri || '';
            this.tag_bootloader_sas_token = tagBootloader.tag_bootloader_sas_token;
            this.created_by = tagBootloader.created_by || '';
            this.created_date = format(parseISO(tagBootloader.created_date), 'yyyy-MM-dd HH:mm') || '';
            this.deleted = tagBootloader.deleted || false;
            this.deleted_by = tagBootloader.deleted_by || '';
            this.deleted_date = format(parseISO(tagBootloader.deleted_date), 'yyyy-MM-dd HH:mm') || '';
            this.tag_bootloader_status = tagBootloader.tag_bootloader_status;
            this.has_deployments = tagBootloader.has_deployments;
            if (tagBootloader.status_history) {
                this.status_history = new Array<TagBootloaderStatus>();
                tagBootloader.status_history.forEach(h => {
                    this.status_history.push(new TagBootloaderStatus(h));
                })
            }
        }
    }
}

export class TagBootloaderStatus {
    tag_bootloader_id: number;
    tag_bootloader_status_code_id: number;
    comment: string;
    created_by: string;
    created_date: string;

    get tagBootloaderStatusCode() {
        return TagBootloaderStatusCode[this.tag_bootloader_status_code_id];
    }

    constructor(tagBootloaderStatus: TagBootloaderStatus) {
        this.tag_bootloader_id = tagBootloaderStatus.tag_bootloader_id;
        this.tag_bootloader_status_code_id = tagBootloaderStatus.tag_bootloader_status_code_id;
        this.comment = tagBootloaderStatus.comment;
        this.created_by = tagBootloaderStatus.created_by;
        this.created_date = format(parseISO(tagBootloaderStatus.created_date), 'yyyy-MM-dd HH:mm');
    }
}

export enum TagBootloaderStatusCode {
    "" = 0,
    "New" = 1,
    "Test Passed" = 2,
    "Test Failed" = 3,
    "Approved" = 4,
    "Retired" = 5
}