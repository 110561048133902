import { CertificateTypeState, CertificateType } from '../types'
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from 'models/apiResponse'


const state: CertificateTypeState = {
    list: [],
    timestampMS: null
}

const getters: GetterTree<CertificateTypeState, any> = {
    getCertificateTypeList: state => () => {
        return state.list;
    },
    getCertificateType: state => (certificateTypeID: number) => {
        return state.list.find(f => f.certificate_type_id === certificateTypeID);
    }
}

const mutations: MutationTree<CertificateTypeState> = {
    setCertificateTypeList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((certificateType: CertificateType, index) => {
            Vue.set(state.list, index, certificateType);
        });
    },
    setCertificateTypeTimestamp(state) {
        // 3 hours
        const expiration = 3 * 60 * 60 * 1000;
        state.timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<CertificateTypeState, any> = {
    async fetchCertificateType({ commit, state, dispatch }, { certificateTypeID }) {
        if (!state.list.find(f => f.certificate_type_id === certificateTypeID)) {
            await dispatch('refreshCertificateTypes');
        }
    },

    async fetchCertificateTypes({ commit, state, dispatch }) {
            if (state.list.length === 0 || (state.timestampMS && new Date().getTime() > state.timestampMS)) {
                await dispatch('refreshCertificateTypes');
            }
    },
    async refreshCertificateTypes({ commit, state }) {
        const url = Vue.IdentityConfig.legacyApiUri + "/ota/certificatetypes";
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "no-cache"
        }
        await fetch(url, options)
            .then(response => response.json() as Promise<ApiResponse<CertificateType>>)
            .then(data => {
                if (data.result) {
                    commit('setCertificateTypeList', data.result);
                    commit('setCertificateTypeTimestamp');
                }
                if (data.errors) {
                    data.errors.forEach(e => Vue.Logger.LogError(e.message));
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}