import { CountryState } from '../types'
import Country from '../../models/country'
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from 'models/apiResponse'

//Get an Country from a Vue component by calling this.$store.state.Country.list;

const state: CountryState = {
    list: [],
    timestampMS: null
}

const getters: GetterTree<CountryState, any> = {
    getCountryList: state => () => {
        return state.list;
    }
}

const mutations: MutationTree<CountryState> = {
    setCountryList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((Country: Country, index) => {
            Vue.set(state.list, index, Country);
        });
    },
    setCountryTimestamp(state) {
        // 24 hours
        const expiration = 24 * 60 * 60 * 1000;
        state.timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<CountryState, any> = {
    async fetchCountryList({ commit, state, dispatch }) {
        if (state.list.length == 0 || (state.timestampMS && new Date().getTime() > state.timestampMS)) {
            await dispatch('refreshCountryList');
        }
    },
    async refreshCountryList({ commit, state }) {
        const url = Vue.IdentityConfig.legacyApiUri + "/country";
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "no-cache"
        };
        await fetch(url, options)
            .then(response => response.json() as Promise<ApiResponse<Country[]>>)
            .then(data => {
                if (data.result && data.result.length > 0) {
                    const Countries = [];
                    data.result.forEach(f => {
                        Countries.push(new Country(f));
                    })
                    commit('setCountryList', Countries);
                    commit('setCountryTimestamp');
                }
                if (data.errors) {
                    data.errors.forEach(e => Vue.Logger.LogError(e.message));
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}