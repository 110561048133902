export default class ComponentType {
    component_type_id: number;
    name: string;
    description: string;
    node_types: string[];

    constructor(component: ComponentType = null) {
        if (component) {
            this.component_type_id = component.component_type_id;
            this.name = component.name;
            this.description = component.description;
            this.node_types = component.node_types;
        }
    }
}