import { GetterTree, MutationTree } from 'vuex'
import { KeyValue, FieldState } from 'store/types'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid'

const state: FieldState = {
    values: new Array<KeyValue>()
}

const datetimeFormats: string[] = [
    'yyyy-MM-dd HH:mm:ss',
    'yyyy-MM-dd hh:mm a',
    'yyyy-MM-dd hh:mm',
    'yyyy-MM-dd',
    "yyyy-MM-dd'T'HH:mm:ssXXX"];

const getters: GetterTree<FieldState, any> = {
    getFieldState: state => (criteria: object) => {
        for (var key in criteria) {
            var found = state.values.find(s => s.key == key);
            if (found && !(found instanceof Date || isValidDate(criteria[key]))) {
                criteria[key] = found.value;
            }
        }
    }
}

const mutations: MutationTree<FieldState> = {
    setFieldState(state: FieldState, criteria: object) {
        for (var key in criteria) {
            if (criteria.hasOwnProperty(key) && !(criteria[key] instanceof Date || isValidDate(criteria[key]))) {
                var found = state.values.find(s => s.key == key);
                if (found)
                    found.value = criteria[key];
                else
                    state.values.push({ key: key, value: criteria[key] } as KeyValue);
            }
        }
    },
}
const isValidDate = function (dateString: string) {
    let result;
    if (Array.isArray(datetimeFormats)) {
        for (let i = 0; i < datetimeFormats.length; i++) {
            result = parse(dateString, datetimeFormats[i], new Date());
            if (isValid(result)) { return true; }
        }
    } else {
        result = parse(dateString, datetimeFormats, new Date());
    }
    result = parseISO(dateString);

    return isValid(result);
}

export default {
    state,
    mutations,
    getters
}