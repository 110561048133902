import { LoaderState } from '../types'
import { GetterTree, MutationTree } from 'vuex'

const state: LoaderState = {
    showLoaderBar: false,
    loaderText: ''
}

const getters: GetterTree<LoaderState, any> = {
    loaderState: state => state.showLoaderBar
}

const mutations: MutationTree<LoaderState> = {
    showLoader(state, payload: boolean) {
        state.showLoaderBar = payload;
        state.loaderText = '';
    },
    showLoaderWithText(state, payload: LoaderState) {
        state.showLoaderBar = payload.showLoaderBar;
        state.loaderText = payload.loaderText;
    }
}

export default {
    state,
    mutations,
    getters
}