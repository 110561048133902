/// <reference types="node" />
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'bootstrap';
import '../less/site.less';
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { store } from '../store'
import { mapActions, mapMutations } from 'vuex'
import Oidc from 'oidc-client'
import SSOUserService from '../services/SSOUserService'
import LoggerService from '../services/LoggerService'
import SolaceService from '../services/SolaceService'
import ClickOutside from 'vue-click-outside'
import { RecycleScroller } from '@akryuminfinitum/vue-virtual-scroller'
import VeeValidate from 'vee-validate'
import FloatingVue from 'floating-vue'
import vSelect from 'vue-select'
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { directive as tablescroll } from '../utils/tablescroll'
import VuePaginate from 'components/controls/vue-paginate'
import VuePaginateCount from 'components/controls/vue-paginate/paginate-links-count.vue'
import IdentityConfig from 'models/identityConfig';
import VueAppInsights from 'vue-application-insights'
import FastDom from 'fastdom'
const GoogleMap = () => import(/* webpackChunkName: "google_map" */'components/controls/googlemap/googlemap.vue.html')
Vue.component('google-map', GoogleMap);

window['fastdom'] = FastDom;
Vue.FastDom = FastDom;

//#region "FontAwesome"
// https://github.com/FortAwesome/vue-fontawesome

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

import { faClock, faCalendarAlt, faMinusSquare, faPlusSquare, faTrashAlt, faFile, faPlayCircle, faCopy } from '@fortawesome/free-regular-svg-icons'
import { faUser, faChartBar, faFolderOpen, faFolder, faLock, faLockOpen, faUpload, faDownload, faEllipsisH, faSync, faExpand, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { faInbox, faCogs, faSitemap, faGlobeAmericas, faBook, faIndustry, faDatabase, faDesktop, faTerminal, faCircle, faExclamation, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { faCaretDown, faCaretUp, faCaretLeft, faSignOutAlt, faChevronLeft, faChevronDown, faChevronUp, faBars, faCheck, faTimesCircle, faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faBatteryEmpty, faBatteryQuarter, faBatteryHalf, faBatteryThreeQuarters, faBatteryFull, faPlug, faCircleNotch, faSun } from '@fortawesome/free-solid-svg-icons'

library.add(faUser, faChartBar, faFolderOpen, faFolder, faPlayCircle, faEllipsisH, faSync, faFile, faUpload, faDownload, faCopy, faTrashAlt, faLock, faLockOpen, faCalendarAlt, faClock, faMinusSquare, faPlusSquare, faCheck, faTimesCircle, faCheckCircle, faInfoCircle, faCircleNotch,
    faSignOutAlt, faCaretDown, faCaretUp, faCaretLeft, faChevronLeft, faChevronDown, faChevronUp, faBars, faBatteryEmpty, faBatteryQuarter, faBatteryHalf, faBatteryThreeQuarters, faBatteryFull, faPlug,
    faInbox, faCogs, faSitemap, faGlobeAmericas, faBook, faIndustry, faDatabase, faDesktop, faTerminal, faCircle, faExclamation, faExclamationTriangle, faExpand, faPlus, faMinus, faSun)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
//#endregion "FontAwesome"

Oidc.Log.level = process.env.NODE_ENV != 'production' ? Oidc.Log.DEBUG : Oidc.Log.INFO;

Vue.config.productionTip = process.env.NODE_ENV != 'production';
Vue.config.silent = true;
Vue.config.errorHandler = function (err, vm, info) {
    Vue.Logger.LogError(err);
}

Vue.component('v-select', vSelect);

export const stateMixin = {
    methods: {
        ...mapMutations(['sortResults']),
        ...mapActions(['setSortColumn', 'setSortColumns', 'setSortDirection'])
    }
};

Vue.mixin(stateMixin);

const dictionary = {
    en: {
        messages: {
            required: (field, args) => `The ${field} is required.`,
            after: (field, args) => `The ${field} must be greater than ${format(parseISO(args), 'yyyy-MM-dd HH:mm')}.`,
            date_format: (field, args) => `The ${field} is invalid.`,
            ext: (field, args) => `The ${field} extension is invalid.`,
            between: (field, args) => `The ${field} must be between ${args[0]} and ${args[1]}.`,
            numeric: (field, args) => `The ${field} must be numeric.`,
            min_value: (field, args) => `The ${field} must be greater than ${Number(args[0]) - 1}.`,
            max_value: (field, args) => `The ${field} must be less than ${Number(args[0]) + 1}.`
        }
    }
}
VeeValidate.Validator.localize('en', dictionary.en);

const validateConfig = {
    errorBagName: 'errors', // change if property conflicts.
    fieldsBagName: 'fields',
    delay: 0,
    locale: 'en',
    dictionary: null,
    strict: true,
    classes: false,
    classNames: {
        touched: 'touched', // the control has been blurred
        untouched: 'untouched', // the control hasn't been blurred
        valid: 'valid', // model is valid
        invalid: 'invalid', // model is invalid
        pristine: 'pristine', // control has not been interacted with
        dirty: 'dirty' // control has been interacted with
    },
    events: 'input|blur',
    inject: true,
    validity: true,
    aria: true,
    useConstraintAttrs: false
};
import isURL from 'validator/lib/isURL';
Vue.use(VeeValidate, validateConfig);

const urlRule = (value) => {
    return isURL(value, {
        protocols: ['http', 'https', 'ftp', 'tcp', 'tcps']
    });
};

VeeValidate.Validator.extend('url', urlRule);

import semverValid from 'semver/functions/valid';
const semVerRule = (value) => {
    return !!semverValid(value);
};

VeeValidate.Validator.extend('semver', semVerRule);

Vue.directive('clickaway', ClickOutside)
Vue.component('RecycleScroller', RecycleScroller)
Vue.use(VueRouter);
Vue.use(VuePaginate);
Vue.use(FloatingVue, {
    themes: {
        tooltip: {
            delay: {
                show: 0,
                hide: 0,
            },
            html: true,

        },
        'info-tooltip': {
            $extend: 'tooltip',
        },
    },
})

Vue.component('paginate-links-count', VuePaginateCount);

Vue.directive('tablescroll', tablescroll)

import * as filters from '../utils/filters'

import fetchIntercept from 'fetch-intercept';

const unregister = fetchIntercept.register({
    request: function (url, config) {
        if (typeof url === 'string' && Vue.IdentityConfig) {
            if (Vue.UserService && Vue.UserService.User) {
                const access_token = Vue.UserService.User.access_token;
                if (access_token) {
                    config.headers['Authorization'] = 'Bearer ' + access_token;
                }
            }
        }

        return [url, config];

    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {
        // Modify the reponse object
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
});


Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
})

const basePath = window.location.protocol + '//' + window.location.host;
const routes: RouteConfig[] = new Array<RouteConfig>(
    //root
    { path: '/', component: () => import(/* webpackChunkName: "root" */'../components/core/core.vue.html'), meta: { title: 'Core Insight Remote' } },
    { path: '/callback', component: () => import(/* webpackChunkName: "callback" */'../public/sso/callback.vue.html'), meta: { title: 'Core Insight Remote' } },

    //device
    {
        path: '/device', component: () => import(/* webpackChunkName: "device" */'../components/areas/device/device.vue.html'), name: 'device',
        children: [
            { path: 'search/:id?', component: () => import(/* webpackChunkName: "device_search" */'../components/areas/device/search/search.vue.html'), meta: { title: 'Device Search', keepAlive: true }, name: 'device_search' },
            { path: 'edit/:id', component: () => import(/* webpackChunkName: "device_edit" */'../components/areas/device/edit/edit.vue.html'), meta: { title: 'Device Edit', permissions: ['auth/legacy.admin'], showTimezones: true, noLocal: true }, name: 'device_edit' },
            { path: 'history/:id', component: () => import(/* webpackChunkName: "device_history" */'../components/areas/device/history/history.vue.html'), meta: { title: 'Device History', permissions: ['auth/legacy.admin'], showTimezones: true, noLocal: true }, name: 'device_history' },
            {
                path: 'settings', component: () => import(/* webpackChunkName: "device_settings" */'../components/areas/device/settings/settings.vue.html'), meta: { title: 'Device Settings', permissions: ['auth/legacy.admin'] }, name: 'device_settings',
                children: [
                    { path: 'search', component: () => import(/* webpackChunkName: "device_settings_search" */'../components/areas/device/settings/search/search.vue.html'), meta: { title: 'Settings Profile Search', permissions: ['auth/legacy.admin'] }, name: 'device_settings_search' },
                    { path: 'new', component: () => import(/* webpackChunkName: "device_settings_new" */'../components/areas/device/settings/new/new.vue.html'), meta: { title: 'New Settings Profile', permissions: ['auth/legacy.admin'] }, name: 'device_settings_new' },
                    { path: 'edit/:id', component: () => import(/* webpackChunkName: "device_settings_edit" */'../components/areas/device/settings/new/new.vue.html'), meta: { title: 'Edit Settings Profile', permissions: ['auth/legacy.admin'] }, name: 'device_settings_edit' },
                ]
            },
            {
                path: '/deviceorder', component: () => import(/* webpackChunkName: "deviceorder" */'../components/areas/order/order.vue.html'), name: 'deviceorder',
                children: [{ path: 'search/:id?', component: () => import(/* webpackChunkName: "device_order_search" */'../components/areas/order/deviceorder/deviceorder.vue.html'), meta: { title: 'Device Order Search', permissions: ['auth/legacy.admin'] }, name: 'device_order_search' },
                ]
            }
        ]
    },
    {
        path: '/package', component: () => import(/* webpackChunkName: "package" */'../components/areas/package/package.vue.html'), name: 'package',
        children: [
            { path: 'search/:id?', component: () => import(/* webpackChunkName: "package_search" */'../components/areas/package/search/search.vue.html'), meta: { title: 'Package Search', permissions: ['auth/legacy.admin'] }, name: "package_search" },
            { path: 'new', component: () => import(/* webpackChunkName: "package_new" */'../components/areas/package/new/new.vue.html'), meta: { title: 'New Package', permissions: ['auth/legacy.admin'] }, name: 'package_new' },
            { path: 'approval', component: () => import(/* webpackChunkName: "package_approval" */'../components/areas/package/approval/approval.vue.html'), meta: { title: 'Package Approval', permissions: ['auth/legacy.admin'] }, name: 'package_approval' },
            { path: 'deploy/:id?', component: () => import(/* webpackChunkName: "package_deploy" */'../components/areas/package/deploy/deploy.vue.html'), meta: { title: 'Deploy Package', permissions: ['auth/legacy.admin'] }, name: 'package_deploy' },
        ]
    },
    {
        path: '/firmware', component: () => import(/* webpackChunkName: "firmware" */'../components/areas/firmware/firmware.vue.html'), name: 'firmware',
        children: [
            { path: 'search/:id?', component: () => import(/* webpackChunkName: "firmware_search" */'../components/areas/firmware/search/search.vue.html'), meta: { title: 'Firmware Search', permissions: ['auth/legacy.admin'] }, name: 'firmware_search' },
            { path: 'new', component: () => import(/* webpackChunkName: "firmware_new" */'../components/areas/firmware/new/new.vue.html'), meta: { title: 'New Firmware', permissions: ['auth/legacy.admin'] }, name: 'firmware_new' },
            { path: 'approval', component: () => import(/* webpackChunkName: "firmware_approval" */'../components/areas/firmware/approval/approval.vue.html'), meta: { title: 'Firmware Approval', permissions: ['auth/legacy.admin'] }, name: 'firmware_approval' },
            { path: 'edit/:id', component: () => import(/* webpackChunkName: "firmware_edit" */'../components/areas/firmware/new/new.vue.html'), meta: { title: 'Edit Firmware', permissions: ['auth/legacy.admin'] }, name: 'firmware_edit' },
            { path: 'deploy/:id?', component: () => import(/* webpackChunkName: "firmware_deploy" */'../components/areas/firmware/deploy/deploy.vue.html'), meta: { title: 'Deploy Firmware', permissions: ['auth/legacy.admin'] }, name: 'firmware_deploy' },
        ]
    },
    {
        path: '/tagfirmware', component: () => import(/* webpackChunkName: "tag_firmware" */'../components/areas/tagfirmware/tagfirmware.vue.html'), name: 'tagfirmware',
        children: [
            { path: 'search/:id?', component: () => import(/* webpackChunkName: "tag_firmware_search" */'../components/areas/tagfirmware/search/search.vue.html'), meta: { title: 'Tag Firmware Search', permissions: ['auth/legacy.admin'] }, name: 'tag_firmware_search' },
            { path: 'new', component: () => import(/* webpackChunkName: "tag_firmware_new" */'../components/areas/tagfirmware/new/new.vue.html'), meta: { title: 'New Tag Firmware', permissions: ['auth/legacy.admin'] }, name: 'tag_firmware_new' },
            //{ path: '/tagfirmware/approval', component: () => import(/* webpackChunkName: "tag_firmware_approval" */'../components/areas/tagfirmware/approval/approval.vue.html'), meta: { title: 'Tag Firmware Approval', permissions: ['auth/legacy.admin'] }, name: 'tag_firmware_approval' },
            { path: 'edit/:id', component: () => import(/* webpackChunkName: "tag_firmware_edit" */'../components/areas/tagfirmware/new/new.vue.html'), meta: { title: 'Edit Tag Firmware', permissions: ['auth/legacy.admin'] }, name: 'tag_firmware_edit' },
            { path: 'deploy/:id?', component: () => import(/* webpackChunkName: "tag_firmware_deploy" */'../components/areas/tagfirmware/deploy/deploy.vue.html'), meta: { title: 'Deploy Tag Firmware', permissions: ['auth/legacy.admin'] }, name: 'tag_firmware_deploy' },
        ]
    },
    {
        path: '/model', component: () => import(/* webpackChunkName: "model" */'../components/areas/model/model.vue.html'), name: 'model',
        children: [
            { path: 'search', component: () => import(/* webpackChunkName: "model_search" */'../components/areas/model/search/search.vue.html'), meta: { title: 'Model Search', permissions: ['auth/legacy.admin'] }, name: 'model_search' },
            { path: 'new', component: () => import(/* webpackChunkName: "model_new" */'../components/areas/model/new/new.vue.html'), meta: { title: 'New Model', permissions: ['auth/legacy.admin'] }, name: 'model_new' },
            { path: 'edit/:id', component: () => import(/* webpackChunkName: "model_edit" */'../components/areas/model/new/new.vue.html'), meta: { title: 'Edit Model', permissions: ['auth/legacy.admin'] }, name: 'model_edit' },
        ]
    },
    {
        path: '/softdevice', component: () => import(/* webpackChunkName: "soft_device" */'../components/areas/softDevice/softdevice.vue.html'), name: 'softdevice',
        children: [
            { path: 'search', component: () => import(/* webpackChunkName: "soft_device_search" */'../components/areas/softDevice/search/search.vue.html'), meta: { title: 'Soft Device Search', permissions: ['auth/legacy.admin'] }, name: 'soft_device_search' },
            { path: 'new', component: () => import(/* webpackChunkName: "soft_device_new" */'../components/areas/softDevice/new/new.vue.html'), meta: { title: 'New Soft Device', permissions: ['auth/legacy.admin'] }, name: 'soft_device_new' },
            //{ path: 'edit/:id', component: () => import(/* webpackChunkName: "soft_device_edit" */'../components/areas/softDevice/new/new.vue.html'), meta: { title: 'Edit Soft Device', permissions: ['auth/legacy.admin'] }, name: 'soft_device_edit' },
        ]
    },
    {
        path: '/bootloader', component: () => import(/* webpackChunkName: "bootloader" */'../components/areas/bootloader/bootloader.vue.html'), name: 'bootloader',
        children: [
            { path: 'search', component: () => import(/* webpackChunkName: "bootloader_search" */'../components/areas/bootloader/search/search.vue.html'), meta: { title: 'Bootloader Search', permissions: ['auth/legacy.admin'] }, name: 'bootloader_search' },
            { path: 'new', component: () => import(/* webpackChunkName: "bootloader_new" */'../components/areas/bootloader/new/new.vue.html'), meta: { title: 'New Bootloader', permissions: ['auth/legacy.admin'] }, name: 'bootloader_new' },
            { path: 'edit/:id', component: () => import(/* webpackChunkName: "bootloader_edit" */'../components/areas/bootloader/new/new.vue.html'), meta: { title: 'Edit Bootloader', permissions: ['auth/legacy.admin'] }, name: 'bootloader_edit' },
            { path: 'deploy/:id?', component: () => import(/* webpackChunkName: "bootloader_deploy" */'../components/areas/bootloader/deploy/deploy.vue.html'), meta: { title: 'Deploy Bootloader', permissions: ['auth/legacy.admin'] }, name: 'bootloader_deploy' },
        ]
    },
    {
        path: '/tagbootloader', component: () => import(/* webpackChunkName: "tag_bootloader" */'../components/areas/tagBootloader/tagbootloader.vue.html'), name: 'tagbootloader',
        children: [
            { path: 'search/:id?', component: () => import(/* webpackChunkName: "tag_bootloader_search" */'../components/areas/tagBootloader/search/search.vue.html'), meta: { title: 'Tag Bootloader Search', permissions: ['auth/legacy.admin'] }, name: 'tag_bootloader_search' },
            { path: 'new', component: () => import(/* webpackChunkName: "tag_bootloader_new" */'../components/areas/tagBootloader/new/new.vue.html'), meta: { title: 'New Tag Bootloader', permissions: ['auth/legacy.admin'] }, name: 'tag_bootloader_new' },
            //{ path: 'approval', component: () => import(/* webpackChunkName: "tag_bootloader_approval" */'../components/areas/tagBootloader/approval/approval.vue.html'), meta: { title: 'Tag Bootloader Approval', permissions: ['auth/legacy.admin'] }, name: 'tag_bootloader_approval' },
            //{ path: 'edit/:id', component: () => import(/* webpackChunkName: "tag_bootloader_edit" */'../components/areas/tagBootloader/new/new.vue.html'), meta: { title: 'Edit Tag Bootloader', permissions: ['auth/legacy.admin'] }, name: 'tag_bootloader_edit' },
            { path: 'deploy/:id?', component: () => import(/* webpackChunkName: "tag_bootloader_deploy" */'../components/areas/tagBootloader/deploy/deploy.vue.html'), meta: { title: 'Deploy Tag Bootloader', permissions: ['auth/legacy.admin'] }, name: 'tag_bootloader_deploy' },
        ]
    },
    {
        path: '/appsettings', component: () => import(/* webpackChunkName: "app_settings" */'../components/areas/appSettings/appsettings.vue.html'), name: 'appsettings',
        children: [
            { path: 'new', component: () => import(/* webpackChunkName: "app_settings_new" */'../components/areas/appSettings/new/new.vue.html'), meta: { title: 'New App Settings', permissions: ['auth/legacy.admin'] }, name: 'app_settings_new' },
        ]
    },
    {
        path: '/deployment', component: () => import(/* webpackChunkName: "deployment" */'../components/areas/deployment/deployment.vue.html'), name: 'deployment',
        children: [
            { path: 'search', component: () => import(/* webpackChunkName: "deployment_search" */'../components/areas/deployment/search/search.vue.html'), meta: { title: 'Deployment Search', permissions: ['auth/legacy.admin'] }, name: 'deployment_search' },
        ]
    },
    {
        path: '/order', component: () => import(/* webpackChunkName: "order" */'../components/areas/order/order.vue.html'), name: 'order',
        children: [
            { path: 'search', component: () => import(/* webpackChunkName: "order_search" */'../components/areas/order/search/search.vue.html'), meta: { title: 'Order Summary Search', permissions: ['auth/legacy.admin'] }, name: 'order_search' },
            { path: 'detailsearch', component: () => import(/* webpackChunkName: "order_detailsearch" */'../components/areas/order/detailsearch/detailsearch.vue.html'), meta: { title: 'Order Detail Search', permissions: ['auth/legacy.admin'] }, name: 'order_detailsearch' },
            { path: 'new', component: () => import(/* webpackChunkName: "order_new" */'../components/areas/order/new/new.vue.html'), meta: { title: 'New Order', permissions: ['auth/legacy.admin'] }, name: 'order_new' },
            { path: 'edit/:id', component: () => import(/* webpackChunkName: "order_edit" */'../components/areas/order/new/new.vue.html'), meta: { title: 'Edit Order', permissions: ['auth/legacy.admin'] }, name: 'order_edit' },
            { path: 'billingsearch', component: () => import(/* webpackChunkName: "order_billing_search" */'../components/areas/order/billingsearch/billingsearch.vue.html'), meta: { title: 'Order Billing Search', permissions: ['auth/legacy.admin'] }, name: 'order_billing_search' },
        ]
    },
    {
        path: '/manufacture', component: () => import(/* webpackChunkName: "manufacture" */'../components/areas/manufacture/manufacture.vue.html'), name: 'manufacture',
        children: [
            {
                path: 'job', component: () => import(/* webpackChunkName: "model" */'../components/areas/manufacture/manufacture.vue.html'), name: 'manufacture_job',
                children: [
                    { path: 'summary_search', component: () => import(/* webpackChunkName: "manufacture_job_search" */'../components/areas/manufacture/summarySearch/summarySearch.vue.html'), meta: { title: 'Job Summary Search', permissions: ['auth/manufacturing-service.admin'], showTimezones: true, noLocal: true }, name: 'manufacture_job_search' },
                    { path: 'detail_search', component: () => import(/* webpackChunkName: "manufacture_detail_search" */'../components/areas/manufacture/detailSearch/detailSearch.vue.html'), meta: { title: 'Job Detail Search', showTimezones: true, noLocal: true, permissions: ['auth/manufacturing-service.admin'] }, name: 'manufacture_detail_search' },
                    { path: 'event_search', component: () => import(/* webpackChunkName: "manufacture_event_search" */'../components/areas/manufacture/eventSearch/eventSearch.vue.html'), meta: { title: 'Job Event Search', showTimezones: true, noLocal: true, permissions: ['auth/manufacturing-service.admin'] }, name: 'manufacture_event_search' },
                    { path: 'new', component: () => import(/* webpackChunkName: "manufacture_job_new" */'../components/areas/manufacture/new/new.vue.html'), meta: { title: 'New Manufacture Job', permissions: ['auth/manufacturing-service.admin'] }, name: 'manufacture_job_new' },
                    { path: 'edit/:id', component: () => import(/* webpackChunkName: "manufacture_job_edit" */'../components/areas/manufacture/new/new.vue.html'), meta: { title: 'Edit Manufacture Job', permissions: ['auth/manufacturing-service.admin'] }, name: 'manufacture_job_edit' },
                    { path: 'qa_result_search', component: () => import(/* webpackChunkName: "manufacture_qa_result_search" */'../components/areas/manufacture/qaResultSearch/qaResultSearch.vue.html'), meta: { title: 'Job QA Result Search', showTimezones: true, noLocal: true, permissions: ['auth/manufacturing-service.admin'] }, name: 'manufacture_qa_result_search' },
                ]
            },//{ path: 'generate', component: () => import(/* webpackChunkName: "manufacture_job_edit" */'../components/areas/manufacture/generateTagID/generateTagID.vue.html'), meta: { title: 'Generate Tag ID', permissions: ['auth/legacy.admin'], environments: ['Local', 'Production'] }, name: 'manufacture_generate_tag_id' },
            {
                path: 'model', component: () => import(/* webpackChunkName: "model" */'../components/areas/manufacture/model/model.vue.html'), name: 'manufacture_model',
                children: [
                    { path: 'search', component: () => import(/* webpackChunkName: "manufacture_model_search" */'../components/areas/manufacture/model/search/search.vue.html'), meta: { title: 'Manufacture Model Search', permissions: ['auth/manufacturing-service.admin'], showTimezones: true, noLocal: true }, name: 'manufacture_model_search' },
                    { path: 'new', component: () => import(/* webpackChunkName: "manufacture_model_new" */'../components/areas/manufacture/model/new/new.vue.html'), meta: { title: 'New Manufacture Model', permissions: ['auth/manufacturing-service.admin'], showTimezones: true, noLocal: true }, name: 'manufacture_model_new' },
                    { path: 'edit/:id', component: () => import(/* webpackChunkName: "manufacture_model_edit" */'../components/areas/manufacture/model/new/new.vue.html'), meta: { title: 'Edit Manufacture Model', permissions: ['auth/manufacturing-service.admin'] }, name: 'manufacture_model_edit' },
                ]
            }
        ],
    },
    {
        path: '/geofence', component: () => import(/* webpackChunkName: "geofence" */'../components/areas/geofence/geofence.vue.html'), name: 'geofence',
        children: [
            { path: 'search', component: () => import(/* webpackChunkName: "geofence_search" */'../components/areas/geofence/search/search.vue.html'), meta: { title: 'Geofence Search' }, name: 'geofence_search' },
            { path: 'new', component: () => import(/* webpackChunkName: "geofence_new" */'../components/areas/geofence/new/new.vue.html'), meta: { title: 'New Geofence' }, name: 'geofence_new' },
        ]
    },
    {
        path: '/reports', component: () => import(/* webpackChunkName: "reports" */'../components/areas/reports/reports.vue.html'), name: 'reports',
        children: [
            { path: 'uptime/:id?', component: () => import(/* webpackChunkName: "reports_uptime" */'../components/areas/reports/uptimeStatus/uptime.vue.html'), meta: { title: 'Uptime Status', showTimezones: true, noLocal: true }, name: 'reports_uptime' },
        ]
    },
    {
        path: '/lookups', component: () => import(/* webpackChunkName: "lookups" */'../components/areas/lookups/lookups.vue.html'), name: 'lookups',
        children: [
            { path: 'newTable', component: () => import(/* webpackChunkName: "lookups_newTable" */'../components/areas/lookups/newTable/newTable.vue.html'), meta: { title: 'New Lookup Table', permissions: ['auth/legacy.admin'] }, name: 'lookups_newTable' },
            { path: 'newCode', component: () => import(/* webpackChunkName: "lookups_newCode" */'../components/areas/lookups/newCode/newCode.vue.html'), meta: { title: 'New Lookup Code', permissions: ['auth/legacy.admin'] }, name: 'lookups_newCode' },
            { path: 'search', component: () => import(/* webpackChunkName: "lookups_search" */'../components/areas/lookups/search/search.vue.html'), meta: { title: 'Lookup Search', permissions: ['auth/legacy.admin'] }, name: 'lookups_search' },
            { path: 'editCode', component: () => import(/* webpackChunkName: "lookups_editCode" */'../components/areas/lookups/editCode/editCode.vue.html'), meta: { title: 'Edit Lookup Code', permissions: ['auth/legacy.admin'] }, name: 'lookups_editCode' },
        ]
    },

    //remote
    {
        path: '/remote/:version/:id', component: () => import(/* webpackChunkName: "remote_root" */'../components/areas/remote/remote.vue.html'), meta: { zone: 'remote', title: 'Remote', permissions: ['auth/legacy.admin'] },
        children: [
            { path: '', component: () => import(/* webpackChunkName: "remote" */'../components/areas/remote/overview/overview.vue.html'), meta: { title: 'Overview', permissions: ['auth/legacy.admin'] }, name: 'remote' },
            {
                path: 'file', component: () => import(/* webpackChunkName: "file_manager" */'../components/areas/remote/fileManager/filemanager.vue.html'), meta: { title: 'File Manager', permissions: ['auth/legacy.admin'] }, name: 'file_manager',
                children: [
                    { path: 'root', component: () => import(/* webpackChunkName: "file_manager_root" */'../components/areas/remote/fileManager/filemanager.vue.html'), meta: { title: 'File Manager', permissions: ['auth/legacy.admin'] }, name: 'file_manager_root' },
                    { path: 'etc', component: () => import(/* webpackChunkName: "file_manager_etc" */'../components/areas/remote/fileManager/filemanager.vue.html'), meta: { title: 'File Manager', permissions: ['auth/legacy.admin'], file_path: '/', file_name: 'etc' }, name: 'file_manager_etc' },
                    { path: 'var', component: () => import(/* webpackChunkName: "file_manager_var" */'../components/areas/remote/fileManager/filemanager.vue.html'), meta: { title: 'File Manager', permissions: ['auth/legacy.admin'], file_path: '/', file_name: 'var' }, name: 'file_manager_var' },

                    { path: 'home', component: () => import(/* webpackChunkName: "file_manager_home" */'../components/areas/remote/fileManager/filemanager.vue.html'), meta: { title: 'File Manager', permissions: ['auth/legacy.admin'], file_path: '/', file_name: 'home' }, name: 'file_manager_home' },
                    { path: 'node', component: () => import(/* webpackChunkName: "file_manager_node" */'../components/areas/remote/fileManager/filemanager.vue.html'), meta: { title: 'File Manager', permissions: ['auth/legacy.admin'], file_path: '/home', file_name: 'Core.Device.Node.Solace' }, name: 'file_manager_node' },
                    { path: 'telit', component: () => import(/* webpackChunkName: "file_manager_telit" */'../components/areas/remote/fileManager/filemanager.vue.html'), meta: { title: 'File Manager', permissions: ['auth/legacy.admin'], file_path: '/home', file_name: 'Core.Device.TelitAPI.Service' }, name: 'file_manager_telit' },

                ]
            },
            //{ path: 'terminal', component: () => import(/* webpackChunkName: "terminal" */'../components/areas/remote/terminal/terminal.vue.html'), meta: { title: 'Terminal', permissions: ['auth/legacy.admin'] }, name: 'terminal' },
            { path: 'terminal', component: () => import(/* webpackChunkName: "terminal" */'../components/areas/remote/terminal_v2/terminal.vue.html'), meta: { title: 'Terminal', permissions: ['auth/legacy.admin'] }, name: 'terminal' }
        ]
    },

    //mesh
    {
        path: '/mesh', component: () => import(/* webpackChunkName: "mesh" */'../components/areas/mesh/mesh.vue.html'), name: 'mesh',
        children: [
            { path: 'search/:id?', component: () => import(/* webpackChunkName: "mesh_search" */'../components/areas/mesh/search/search.vue.html'), meta: { title: 'Node Search', keepAlive: true }, name: 'mesh_search' },
            { path: 'network', component: () => import(/* webpackChunkName: "mesh_network" */'../components/areas/mesh/network/network.vue.html'), meta: { title: 'Mesh Network', keepAlive: true }, name: 'mesh_network' },
            { path: 'tree', component: () => import(/* webpackChunkName: "mesh_tree" */'../components/areas/mesh/tree/tree.vue.html'), meta: { title: 'Mesh Tree', keepAlive: true }, name: 'mesh_tree' },
            { path: 'map/:id?', component: () => import(/* webpackChunkName: "mesh_map" */'../components/areas/mesh/map/map.vue.html'), meta: { title: 'Mesh Map', showTimezones: true, noLocal: true, keepAlive: true }, name: 'mesh_map' },
            { path: 'approximationMap/:id', component: () => import(/* webpackChunkName: "approximation_map" */'../components/areas/mesh/approximationMap/approximationMap.vue.html'), meta: { title: 'Position Approximation Map', showTimezones: true, noLocal: true, keepAlive: true }, name: 'approximation_map' },
            {
                path: 'ota', component: () => import(/* webpackChunkName: "mesh_ota" */'../components/areas/mesh/ota/ota.vue.html'), name: 'mesh_ota',
                children: [
                    {
                        path: 'certificate', component: () => import(/* webpackChunkName: "mesh_ota_certificate" */'../components/areas/mesh/ota/ota.vue.html'), name: 'mesh_ota_certificate',
                        children: [
                            { path: 'search', component: () => import(/* webpackChunkName: "mesh_ota_certificate_search" */'../components/areas/mesh/ota/certificate/search/search.vue.html'), meta: { title: 'Certificate Search', permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_certificate_search' },
                            { path: 'new', component: () => import(/* webpackChunkName: "mesh_ota_certificate_new" */'../components/areas/mesh/ota/certificate/new/new.vue.html'), meta: { title: 'New Certificate', showTimezones: true, noLocal: true, permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_certificate_new' },
                            { path: 'edit/:id', component: () => import(/* webpackChunkName: "mesh_ota_certificate_edit" */'../components/areas/mesh/ota/certificate/new/new.vue.html'), meta: { title: 'Edit Certificate', showTimezones: true, noLocal: true, permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_certificate_edit' },
                            { path: 'node/:id', component: () => import(/* webpackChunkName: "mesh_ota_certificate_node" */'../components/areas/mesh/ota/certificate/node/display/display.vue.html'), meta: { title: 'Node Certificates', showTimezones: true, noLocal: true, permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_certificate_node' },
                            {
                                path: 'template', component: () => import(/* webpackChunkName: "mesh_ota_certificate_template" */'../components/areas/mesh/ota/ota.vue.html'), name: 'mesh_ota_certificate_template',
                                children: [
                                    { path: 'search', component: () => import(/* webpackChunkName: "mesh_ota_certificate_search_template" */'../components/areas/mesh/ota/certificate/template/search/search.vue.html'), meta: { title: 'Certificate Template Search', permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_certificate_template_search' },
                                    { path: 'new', component: () => import(/* webpackChunkName: "mesh_ota_certificate_new_template" */'../components/areas/mesh/ota/certificate/template/new/new.vue.html'), meta: { title: 'New Certificate Template', showTimezones: true, noLocal: true, permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_certificate_template_new' },
                                    { path: 'edit/:id', component: () => import(/* webpackChunkName: "mesh_ota_certificate_edit_template" */'../components/areas/mesh/ota/certificate/template/new/new.vue.html'), meta: { title: 'Edit Certificate Template', showTimezones: true, noLocal: true, permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_certificate_template_edit' },
                                ]
                            }
                        ]
                    },
                    {
                        path: 'componentType', component: () => import(/* webpackChunkName: "mesh_ota_component_type" */'../components/areas/mesh/ota/ota.vue.html'), name: 'mesh_ota_component_type',
                        children: [
                            { path: 'search', component: () => import(/* webpackChunkName: "mesh_ota_component_type_search" */'../components/areas/mesh/ota/componentType/search/search.vue.html'), meta: { title: 'Component Type Search', permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_component_type_search' },
                            { path: 'new', component: () => import(/* webpackChunkName: "mesh_ota_component_type_new" */'../components/areas/mesh/ota/componentType/new/new.vue.html'), meta: { title: 'New Component Type', permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_component_type_new' },
                            { path: 'edit/:id', component: () => import(/* webpackChunkName: "mesh_ota_component_type_edit" */'../components/areas/mesh/ota/componentType/new/new.vue.html'), meta: { title: 'Edit Component Type', permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_component_type_edit' },
                        ]
                    },
                    {
                        path: 'component', component: () => import(/* webpackChunkName: "mesh_ota_component" */'../components/areas/mesh/ota/ota.vue.html'), name: 'mesh_ota_component',
                        children: [
                            { path: 'search', component: () => import(/* webpackChunkName: "mesh_ota_component_search" */'../components/areas/mesh/ota/component/search/search.vue.html'), meta: { title: 'Component Search', permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_component_search' },
                            { path: 'new', component: () => import(/* webpackChunkName: "mesh_ota_component_new" */'../components/areas/mesh/ota/component/new/new.vue.html'), meta: { title: 'New Component', permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_component_new' },
                            { path: 'edit/:id', component: () => import(/* webpackChunkName: "mesh_ota_component_edit" */'../components/areas/mesh/ota/component/new/new.vue.html'), meta: { title: 'Edit Component', permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_component_edit' },
                        ]
                    },
                    {
                        path: 'package', component: () => import(/* webpackChunkName: "mesh_ota_package" */'../components/areas/mesh/ota/ota.vue.html'), name: 'mesh_ota_package',
                        children: [
                            { path: 'search', component: () => import(/* webpackChunkName: "mesh_ota_package_search" */'../components/areas/mesh/ota/package/search/search.vue.html'), meta: { title: 'Package Search', permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_package_search' },
                            { path: 'new', component: () => import(/* webpackChunkName: "mesh_ota_package_new" */'../components/areas/mesh/ota/package/new/new.vue.html'), meta: { title: 'New Package', permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_package_new' },
                            { path: 'edit/:id', component: () => import(/* webpackChunkName: "mesh_ota_package_edit" */'../components/areas/mesh/ota/package/new/new.vue.html'), meta: { title: 'Edit Package', permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_package_edit' },
                            { path: 'deploy/:id?', component: () => import(/* webpackChunkName: "mesh_ota_package_deploy" */'../components/areas/mesh/ota/package/deploy/deploy.vue.html'), meta: { title: 'Deploy Package', permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_package_deploy' },
                            {
                                path: 'operation', component: () => import(/* webpackChunkName: "mesh_ota_package_operation" */'../components/areas/mesh/ota/ota.vue.html'), name: 'mesh_ota_package_operation',
                                children: [
                                    { path: 'search', component: () => import(/* webpackChunkName: "mesh_ota_package_operation_search" */'../components/areas/mesh/ota/package/operationSearch/search.vue.html'), meta: { title: 'Package Operation Search', permissions: ['auth/legacy.admin'] }, name: 'mesh_ota_package_operation_search' }
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                path: 'dmr', component: () => import(/* webpackChunkName: "mesh_dmr" */'../components/areas/mesh/dmr/dmr.vue.html'), name: 'mesh_dmr',
                children: [
                    { path: 'edit/:id', component: () => import(/* webpackChunkName: "mesh_dmr_edit" */'../components/areas/mesh/dmr/edit/edit.vue.html'), meta: { title: 'DMR Edit', permissions: ['auth/legacy.admin'], showTimezones: true, noLocal: true }, name: 'mesh_dmr_edit' },
                ]
            }
        ]
    },
    {
        path: '/devicesms', component: () => import(/* webpackChunkName: "devicesms" */'../components/areas/sim/devicesim/devicesim.vue.html'), name: 'devicesms',
        children: [
            { path: 'search/:id?', component: () => import(/* webpackChunkName: "device_sms_search" */'../components/areas/sim/devicesim/devicesim.vue.html'), meta: { title: 'Device SMS Search', permissions: ['auth/legacy.admin'] }, name: 'device_sms_search' },
        ]
    },
    {
        path: '/admin', component: () => import(/* webpackChunkName: "admin" */'../components/areas/admin/admin.vue.html'), name: 'admin',
        children: [
            {
                path: 'contact', component: () => import(/* webpackChunkName: "contact" */'../components/areas/admin/admin.vue.html'), name: 'contact',
                children: [
                    { path: 'search', component: () => import(/* webpackChunkName: "contact_search" */'../components/areas/admin/contact/search/search.vue.html'), meta: { title: 'Contact Search', permissions: ['auth/legacy.admin'] }, name: 'contact_search' },
                    { path: 'new', component: () => import(/* webpackChunkName: "contact_new" */'../components/areas/admin/contact/new/new.vue.html'), meta: { title: 'New Contact', permissions: ['auth/legacy.admin'] }, name: 'contact_new' },
                    { path: 'edit/:id', component: () => import(/* webpackChunkName: "contact_edit" */'../components/areas/admin/contact/new/new.vue.html'), meta: { title: 'Edit Contact', permissions: ['auth/legacy.admin'] }, name: 'contact_edit' }
                ]
            }]
    },
);

const router = new VueRouter({
    mode: 'history',
    routes
});

import App from './App.vue';
import { isNullOrUndefined } from 'utils/isNullOrUndefined';

const url = '/api/Home';
const options = {
    method: 'GET'
};

class Configuration {
    identityConfig: IdentityConfig;
    instrumentationKey: string;
}

fetch(url, options)
    .then(response => response.json() as Promise<Configuration>)
    .then(data => {
        Vue.IdentityConfig = new IdentityConfig(data.identityConfig);

        Vue.UserManagerSettings = {
            authority: Vue.IdentityConfig.authority,
            app_id: Vue.IdentityConfig.appID,
            app_secret: Vue.IdentityConfig.appSecret,
            sealed_app_id: Vue.IdentityConfig.sealedAppID,
            redirect_uri: Vue.IdentityConfig.redirectUri,
            post_logout_redirect_uri: Vue.IdentityConfig.postLogoutRedirectUri,

            loadUserInfo: true
        };

        Vue.use(VueAppInsights, {
            baseName: 'Remote', // prefix to track route changes as page views with AppInsights
            id: data.instrumentationKey || "",
            router
        })


        function DeferUser(to, from, next) {
            if (Vue.UserService && Vue.UserService.User !== undefined && Vue.UserService.SolaceCredentials !== undefined) {
                Vue.UserService.clearState();
                store.commit('setIsAuthenticated', true);
                store.commit('setIsAuthenticated', true);

                next();
                const permissions = Vue.UserService.User.profile.permissions;
                var routeRules: Array<string> = to.matched.reduce((acc, route) => {
                    return route.meta.permissions && !acc.length ? route.meta.permissions : acc
                }, [])

                if (routeRules.length == 0 || permissions && routeRules.some(r => permissions.includes(r))) {
                    next();
                }
                else {
                    next({ path: '/' });
                }
            }
            else {
                window.setTimeout(function () { DeferUser(to, from, next) }, 50);
            }
        };

        function CheckEnvironments(to, from, next) {
            var currentEnv = Vue.IdentityConfig.environment;
            var routeEnvironments: Array<string> = to.matched.reduce((acc, route) => {
                return route.meta.environments && !acc.length ? route.meta.environments : acc
            }, []);

            if (routeEnvironments.length == 0 || currentEnv && routeEnvironments.find(env => env == currentEnv)) {
            }
            else {
                next({ path: '/' });
            }
        };

        router.beforeEach((to, from, next) => {
            Vue.appInsights.startTrackPage(basePath + to.fullPath);
            store.commit('setIsAuthenticated', false);
            store.commit('showLoader', false);

            //check environments
            CheckEnvironments(to, from, next);

            if (to.path === '/callback' && !isNullOrUndefined(to.query['glnFedSsoTicket'])) {
                next()
            }
            else {

                //check we have the user service.
                if (Vue.UserService === undefined) {
                    Vue.UserService = new SSOUserService();
                }
                Vue.UserService.GetUser(to.path).then(function () {
                    DeferUser(to, from, next);
                })
            }
        })

        router.afterEach((to, from) => {
            Vue.appInsights.stopTrackPage(basePath + to.fullPath, to.fullPath);
        });

        var onResize = function () {
            boot.$emit("resize");
        };

        var onEnterPressed = function (e) {
            var key = e.which || e.keyCode;
            if (key === 13)
                boot.$emit("enterPressed");
        }

        var onButtonClicked = function (e) {
            if ((e.target.type == 'button' || e.target.type == 'submit') && (e.target.value == 'Search' || e.target.value == 'Save' || e.target.value == 'Export'))
                boot.$emit("buttonClicked");
        }

        // This is the applications main Vue instance
        var boot = new Vue({
            //el: '#app-root',
            render: h => h(App),
            router: router,
            store,
            created() {
                Vue.Logger = new LoggerService(process.env.NODE_ENV);
                Oidc.Log.logger = Vue.Logger;
                var self = this;
                var unsubscribe = self.$store.subscribe(
                    function (mutation, state) {
                        if (mutation.type == 'setIsAuthenticated') {
                            if (mutation.payload == true && Vue.UserService.User != null) {
                                Vue.Solace = new SolaceService();

                                var methods = new Map<string, (...args: any[]) => void>();
                                methods.set("connected", (deviceid: string) => {
                                    var timestamp = formatISO(new Date());
                                    Vue.Logger.LogInfo(`${deviceid} connected to Solace at ${timestamp}`);
                                    self.$store.commit('addConnection', { deviceId: deviceid, timestamp: timestamp });
                                });

                                methods.set("disconnected", (deviceid: string) => {
                                    var timestamp = formatISO(new Date());
                                    Vue.Logger.LogInfo(`${deviceid} disconnected from Solace at ${timestamp}`);
                                    self.$store.commit('removeConnection', deviceid);
                                });

                                Vue.Solace.On(methods);
                                unsubscribe();
                            }
                        }
                    }
                )

                window.addEventListener('resize', onResize);
                window.addEventListener('keypress', onEnterPressed, true);
                window.addEventListener('click', onButtonClicked, true);

                var trimInputs = () => {
                    var inputs = Array.from(this.$el.querySelectorAll('input[type="text"]'));
                    inputs.forEach((input: any) => {
                        input.value = input.value.toString().trim();
                        input.dispatchEvent(new Event('input'));
                    });
                }

                this.$root.$on('enterPressed', trimInputs);

                this.$root.$on('buttonClicked', trimInputs);
            },
            beforeDestroy: function () {
                window.removeEventListener('resize', onResize);
                window.removeEventListener('keypress', onEnterPressed);
                window.removeEventListener('click', onButtonClicked);
            }
        });
        boot.$mount('#app');
    });