import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import Common from 'utils/common';
import { RouteRecord } from 'vue-router';

interface MenuItem {
    title: string;
    path: string;
    name: string;
    iconClass: string;
    active: boolean;
    children: MenuItem[];
    permissions?: string[];
    menuFunction?: MenuFunction;
    environments?: string[];
}

@Component
export default class NavComponent extends Vue {
    siteMenuItems: MenuItem[] = SiteMenuItems;
    remoteMenuItems: MenuItem[] = RemoteMenuItems;
    permissions: string[] = [];
    permissionsAdded: boolean = false;
    get menuItems(): MenuItem[] {
        if (!this.permissionsAdded)
            return [];
        if (this.$route.matched.some(m => m.meta.zone == 'remote')) {
            var remoteMenuItemsFilteredByEnvironment = this.filterEnvironments(this.remoteMenuItems);
            return this.filterPermissions(remoteMenuItemsFilteredByEnvironment);
        } else {
            var siteMenuItemsFilteredByEnvironment = this.filterEnvironments(this.siteMenuItems);
            return this.filterPermissions(siteMenuItemsFilteredByEnvironment);
        }
    }

    hasChildren(item: MenuItem): boolean {
        return item.children.length > 0;
    }

    isRouterLink(item: MenuItem): boolean {
        return item.children.length == 0 && item.menuFunction == undefined;
    }

    isMenuFunction(item: MenuItem): boolean {
        return item.menuFunction != undefined;
    }

    filterPermissions(menuItems: MenuItem[]) {
        // This is not route authorization, it merely determines display of nav menu
        // use vue-router route guards to deny access to pages
        var self = this;
        if (!self.permissions || self.permissions.length < 1)
            self.permissions = [];
        return menuItems.filter(function f(item) {
            if (item.permissions && self.permissions) {
                if (!item.permissions.some(r => self.permissions.includes(r))) {
                    return false;
                }
            }
            if (item.children && item.children.length > 0) {
                return (item.children = item.children.filter(f)).length;
            }
            return true;
        });
    }

    filterEnvironments(menuItems: MenuItem[]) {
        // This is not route authorization, it merely determines display of nav menu
        // use vue-router route guards to deny access to pages
        var self = this;
        var currentEnv = Vue.IdentityConfig.environment;
        var filtered = menuItems.filter(function f(item) {
            var currentItemVisible = true;
            if (!item.environments || item.environments.length < 1) {
                currentItemVisible = true;
            } else {
                var found = item.environments.find(env => env == currentEnv);
                if (found)
                    currentItemVisible = true;
                else
                    currentItemVisible = false;
            }

            if (currentItemVisible && item.children && item.children.length > 1)
                item.children = self.filterEnvironments(item.children);

            return currentItemVisible;
        });
        return filtered;
    }

    @Watch('$route')
    onRouteChanged(to: any, from: any) {
        this.getActiveMenuItem(to.matched);
    }

    created() {
        this.getActiveMenuItem(this.$route.matched);

        var self = this as NavComponent;
        self.DeferUser(self);
    }

    DeferUser(self: NavComponent) {
        if (Vue.UserService && Vue.UserService.User !== undefined) {
            self.permissions = Vue.UserService.User.profile.permissions;
            self.permissionsAdded = true;
            self.getActiveMenuItem(self.$route.matched);
        }
        else {
            window.setTimeout(function () { self.DeferUser(self) }, 50);
        }
    };

    getActiveMenuItem(matched: RouteRecord[]) {
        loop:
        for (let item of this.menuItems) {
            item.active = false;
            for (let child of item.children) {
                child.active = false;
                for (let subchild of child.children) {
                    subchild.active = false;
                    for (let subchild2 of subchild.children) {
                        subchild2.active = false;
                        if (matched.some(m => m.path == subchild2.path)) {
                            subchild2.active = subchild.active = child.active = item.active = true;
                            break loop;
                        }
                    }
                    if (matched.some(m => m.path == subchild.path)) {
                        subchild.active = child.active = item.active = true;
                        break loop;
                    }
                }
                if (matched.some(m => m.path == child.path)) {
                    child.active = item.active = true;
                    break loop;
                }
            }
            if (matched.some(m => m.path == item.path)) {
                item.active = true;
                break loop;
            }
        }
    }

    toggle(menuItem: MenuItem) {
        for (let item of this.menuItems) {
            if (item == menuItem) {
                item.active = !item.active;
            }
            else {
                item.active = false;

                for (let child of item.children) {
                    if (child == menuItem) {
                        item.active = true;
                        child.active = !child.active;
                    }
                    else {
                        child.active = false;
                        for (let subchild of child.children) {
                            if (subchild == menuItem) {
                                child.active = true;
                                item.active = true;
                                subchild.active = !subchild.active;
                            }
                            else {
                                subchild.active = false;
                            }
                        }
                    }
                }
            }
        }

        window.setTimeout(() => {
            this.$root.$emit("resize");
        }, 100);
    }
}

export declare type MenuFunction = () => void;

const SiteMenuItems: Array<MenuItem> = new Array<MenuItem>(
    {
        title: 'Device',
        path: '/device',
        name: 'device',
        iconClass: 'inbox',
        active: false,
        children: [
            {
                title: 'Search',
                path: '/device/search',
                name: 'device_search',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: 'Order Search',
                path: '/deviceorder/search',
                name: 'device_order_search',
                iconClass: '',
                active: false,
                permissions: ['auth/legacy.admin'],
                children: []
            },
            {
                title: 'SMS Search',
                path: '/devicesms/search',
                name: 'device_sms_search',
                iconClass: '',
                active: false,
                permissions: ['auth/legacy.admin'],
                children: []
            },
            {
                title: 'Settings',
                path: '/device/settings',
                name: '',
                iconClass: '',
                active: false,
                permissions: ['auth/legacy.admin'],
                children: [
                    {
                        title: 'Search',
                        path: '/device/settings/search',
                        name: 'device_settings_search',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'New',
                        path: '/device/settings/new',
                        name: 'device_settings_new',
                        iconClass: '',
                        active: false,
                        children: []
                    }
                ]
            },
            {
                title: 'Remote',
                path: '',
                name: '',
                iconClass: '',
                active: false,
                permissions: ['auth/legacy.admin'],
                children: [],
                menuFunction: Common.showRemoteModal
            }
        ]
    },
    {
        title: 'Device Maint',
        path: '',
        name: '',
        iconClass: 'cogs',
        active: false,
        permissions: ['auth/legacy.admin'],
        children: [
            {
                title: 'Package',
                path: '/package',
                name: '',
                iconClass: '',
                active: false,
                children: [
                    {
                        title: 'Search',
                        path: '/package/search',
                        name: 'package_search',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'New',
                        path: '/package/new',
                        name: 'package_new',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'Approval',
                        path: '/package/approval',
                        name: 'package_approval',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'Deploy',
                        path: '/package/deploy',
                        name: 'package_deploy',
                        iconClass: '',
                        active: false,
                        children: []
                    }
                ]
            },
            {
                title: 'Firmware',
                path: '/firmware',
                name: '',
                iconClass: '',
                active: false,
                children: [
                    {
                        title: 'Search',
                        path: '/firmware/search',
                        name: 'firmware_search',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'New',
                        path: '/firmware/new',
                        name: 'firmware_new',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'Approval',
                        path: '/firmware/approval',
                        name: 'firmware_approval',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'Deploy',
                        path: '/firmware/deploy',
                        name: 'firmware_deploy',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                ]
            },
            {
                title: 'Tag Firmware',
                path: '/tagfirmware',
                name: '',
                iconClass: '',
                active: false,
                children: [
                    {
                        title: 'Search',
                        path: '/tagfirmware/search',
                        name: 'tag_firmware_search',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'New',
                        path: '/tagfirmware/new',
                        name: 'tag_firmware_new',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'Deploy',
                        path: '/tagfirmware/deploy',
                        name: 'tag_firmware_deploy',
                        iconClass: '',
                        active: false,
                        children: []
                    }
                ]
            },
            {
                title: 'Model',
                path: '/model',
                name: '',
                iconClass: '',
                active: false,
                children: [
                    {
                        title: 'Search',
                        path: '/model/search',
                        name: 'model_search',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    //{
                    //    title: 'New',
                    //    path: '/model/new',
                    //    name: 'model_new',
                    //    iconClass: '',
                    //    active: false,
                    //    children: []
                    //}
                ]
            },
            {
                title: 'Soft Device',
                path: '/softdevice',
                name: '',
                iconClass: '',
                active: false,
                children: [
                    {
                        title: 'Search',
                        path: '/softdevice/search',
                        name: 'soft_device_search',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'New',
                        path: '/softdevice/new',
                        name: 'soft_device_new',
                        iconClass: '',
                        active: false,
                        children: []
                    }
                ]
            },
            {
                title: 'Bootloader',
                path: '/bootloader',
                name: '',
                iconClass: '',
                active: false,
                children: [
                    {
                        title: 'Search',
                        path: '/bootloader/search',
                        name: 'bootloader_search',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'New',
                        path: '/bootloader/new',
                        name: 'bootloader_new',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'Deploy',
                        path: '/bootloader/deploy',
                        name: 'bootloader_deploy',
                        iconClass: '',
                        active: false,
                        children: []
                    }
                ]
            },
            {
                title: 'Tag Bootloader',
                path: '/tagbootloader',
                name: '',
                iconClass: '',
                active: false,
                children: [
                    {
                        title: 'Search',
                        path: '/tagbootloader/search',
                        name: 'tag_bootloader_search',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'New',
                        path: '/tagbootloader/new',
                        name: 'tag_bootloader_new',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'Deploy',
                        path: '/tagbootloader/deploy',
                        name: 'tag_bootloader_deploy',
                        iconClass: '',
                        active: false,
                        children: []
                    }
                ]
            },
            {
                title: "App Settings",
                path: '/app_settings',
                name: ' ',
                iconClass: '',
                active: false,
                children: [
                    {
                        title: 'New',
                        path: '/app_settings/new',
                        name: 'app_settings_new',
                        iconClass: '',
                        active: false,
                        children: []
                    }
                ]
            },
            {
                title: "Deployment Search",
                path: "/deployment/search",
                name: "deployment_search",
                iconClass: '',
                active: false,
                children: []
            }
        ]
    },
    {
        title: 'Mesh',
        path: '/mesh',
        name: 'mesh',
        iconClass: 'sitemap',
        active: false,
        children: [
            //{
            //    title: 'Network',
            //    path: '/mesh/network',
            //    name: 'mesh_network',
            //    iconClass: '',
            //    active: false,
            //    children: []
            //},
            //{
            //    title: 'Tree',
            //    path: '/mesh/tree',
            //    name: 'mesh_tree',
            //    iconClass: '',
            //    active: false,
            //    children: []
            //},
            {
                title: 'Search',
                path: '/mesh/search',
                name: 'mesh_search',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: 'Map',
                path: '/mesh/map',
                name: 'mesh_map',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: 'OTA',
                path: '/mesh/ota',
                name: 'ota',
                iconClass: '',
                active: false,
                permissions: ['auth/legacy.admin'],
                children: [
                    {
                        title: 'Certificate',
                        path: '/mesh/ota/certificate',
                        name: 'mesh_ota_certificate',
                        iconClass: '',
                        active: false,
                        permissions: ['auth/legacy.admin'],
                        children: [
                            {
                                title: 'Search Certificates',
                                path: '/mesh/ota/certificate/search',
                                name: 'mesh_ota_certificate_search',
                                iconClass: '',
                                active: false,
                                permissions: ['auth/legacy.admin'],
                                children: []
                            },
                            {
                                title: 'New Certificate',
                                path: '/mesh/ota/certificate/new',
                                name: 'mesh_ota_certificate_new',
                                iconClass: '',
                                active: false,
                                permissions: ['auth/legacy.admin'],
                                children: []
                            },
                            {
                                title: 'Search Templates',
                                path: '/mesh/ota/certificate/template/search',
                                name: 'mesh_ota_certificate_template_search',
                                iconClass: '',
                                active: false,
                                permissions: ['auth/legacy.admin'],
                                children: []
                            },
                            {
                                title: 'New Template',
                                path: '/mesh/ota/certificate/template/new',
                                name: 'mesh_ota_certificate_template_new',
                                iconClass: '',
                                active: false,
                                permissions: ['auth/legacy.admin'],
                                children: []
                            },
                        ]
                    },
                    {
                        title: 'Component',
                        path: '/mesh/ota/component',
                        name: 'mesh_ota_component',
                        iconClass: '',
                        active: false,
                        permissions: ['auth/legacy.admin'],
                        children: [
                            {
                                title: 'Search',
                                path: '/mesh/ota/component/search',
                                name: 'mesh_ota_component_search',
                                iconClass: '',
                                active: false,
                                permissions: ['auth/legacy.admin'],
                                children: []
                            },
                            {
                                title: 'New',
                                path: '/mesh/ota/component/new',
                                name: 'mesh_ota_component_new',
                                iconClass: '',
                                active: false,
                                permissions: ['auth/legacy.admin'],
                                children: []
                            },
                        ]
                    },
                    {
                        title: 'Package',
                        path: '/mesh/ota/package',
                        name: 'mesh_ota_package',
                        iconClass: '',
                        active: false,
                        permissions: ['auth/legacy.admin'],
                        children: [
                            {
                                title: 'Search',
                                path: '/mesh/ota/package/search',
                                name: 'mesh_ota_package_search',
                                iconClass: '',
                                active: false,
                                permissions: ['auth/legacy.admin'],
                                children: []
                            },
                            {
                                title: 'New',
                                path: '/mesh/ota/package/new',
                                name: 'mesh_ota_package_new',
                                iconClass: '',
                                active: false,
                                permissions: ['auth/legacy.admin'],
                                children: []
                            },
                            {
                                title: 'Deploy',
                                path: '/mesh/ota/package/deploy',
                                name: 'mesh_ota_package_deploy',
                                iconClass: '',
                                active: false,
                                permissions: ['auth/legacy.admin'],
                                children: []
                            },
                            {
                                title: 'Operation Search',
                                path: '/mesh/ota/package/operation/search',
                                name: 'mesh_ota_package_operation_search',
                                iconClass: '',
                                active: false,
                                permissions: ['auth/legacy.admin'],
                                children: []
                            },
                        ]
                    },
                    {
                        title: 'Component Type',
                        path: '/mesh/ota/componentType',
                        name: 'mesh_ota_component_type',
                        iconClass: '',
                        active: false,
                        permissions: ['auth/legacy.admin'],
                        children: [
                            {
                                title: 'Search',
                                path: '/mesh/ota/componentType/search',
                                name: 'mesh_ota_component_type_search',
                                iconClass: '',
                                active: false,
                                permissions: ['auth/legacy.admin'],
                                children: []
                            },
                            {
                                title: 'New',
                                path: '/mesh/ota/componentType/new',
                                name: 'mesh_ota_component_type_new',
                                iconClass: '',
                                active: false,
                                permissions: ['auth/legacy.admin'],
                                children: []
                            }]
                    },
                ]
            },
        ]
    },
    {
        title: 'Geofence',
        path: '/geofence',
        name: '',
        iconClass: 'globe-americas',
        active: false,
        children: [
            {
                title: 'Search',
                path: '/geofence/search',
                name: 'geofence_search',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: 'New',
                path: '/geofence/new',
                name: 'geofence_new',
                iconClass: '',
                active: false,
                children: []
            }
        ]
    },
    {
        title: 'Order',
        path: '/order',
        name: '',
        iconClass: 'book',
        active: false,
        permissions: ['auth/legacy.admin'],
        children: [
            {
                title: 'Summary Search',
                path: '/order/search',
                name: 'order_search',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: 'Detail Search',
                path: '/order/detailsearch',
                name: 'order_detailsearch',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: 'New',
                path: '/order/new',
                name: 'order_new',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: 'Billing Search',
                path: '/order/billingsearch',
                name: 'order_billing_search',
                iconClass: '',
                active: false,
                children: []
            }
        ]
    },
    {
        title: 'Manufacturing',
        path: '/manufacture',
        name: '',
        iconClass: 'industry',
        active: false,
        permissions: ['auth/manufacturing-service.admin'],
        children: [
            {
                title: 'Job',
                path: '/manufacture/job',
                name: '',
                iconClass: '',
                active: false,
                children: [
                    {
                        title: 'New',
                        path: '/manufacture/job/new',
                        name: 'manufacture_job_new',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'Summary Search',
                        path: '/manufacture/job/summarySearch',
                        name: 'manufacture_job_search',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'Detail Search',
                        path: '/manufacture/job/detailSearch',
                        name: 'manufacture_detail_search',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'Event Search',
                        path: '/manufacture/job/eventSearch',
                        name: 'manufacture_event_search',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'QA Result Search',
                        path: '/manufacture/job/qaResultSearch',
                        name: 'manufacture_qa_result_search',
                        iconClass: '',
                        active: false,
                        children: []
                    }
                ]
            },
            {
                title: 'Model',
                path: '/manufacture/model',
                name: '',
                iconClass: '',
                active: false,
                children: [
                    {
                        title: 'New',
                        path: '/manufacture/model/new',
                        name: 'manufacture_model_new',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'Search',
                        path: '/manufacture/model/search',
                        name: 'manufacture_model_search',
                        iconClass: '',
                        active: false,
                        children: []
                    }
                ]
            },
            /*{
                title: 'Generate Tag ID',
                path: '/manufacture/generateTagID',
                name: 'manufacture_generate_tag_id',
                iconClass: '',
                active: false,
                environments: ['Local', 'Production'],
                children: []
            }*/
        ]
    },
    {
        title: 'Reports',
        path: '/reports',
        name: '',
        iconClass: 'chart-bar',
        active: false,
        children: [
            {
                title: 'Uptime Status',
                path: '/reports/uptime',
                name: 'reports_uptime',
                iconClass: '',
                active: false,
                children: []
            }
        ]
    },
    {
        title: 'Lookup Codes',
        path: '/lookups',
        name: '',
        iconClass: 'database',
        active: false,
        permissions: ['auth/legacy.admin'],
        children: [
            {
                title: 'Search',
                path: '/lookups/search',
                name: 'lookups_search',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: 'New',
                path: '/lookups/newCode',
                name: 'lookups_newCode',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: 'New Table',
                path: '/lookups/newTable',
                name: 'lookups_newTable',
                iconClass: '',
                active: false,
                children: []
            }
        ]
    },
    {
        title: 'Admin',
        path: '/admin',
        name: '',
        iconClass: 'desktop',
        active: false,
        permissions: ['auth/legacy.admin'],
        children: [
            {
                title: 'Contact',
                path: '/admin/contact',
                name: '',
                iconClass: '',
                active: false,
                permissions: ['auth/legacy.admin'],
                children: [
                    {
                        title: 'Search',
                        path: '/admin/contact/search',
                        name: 'contact_search',
                        iconClass: '',
                        active: false,
                        children: []
                    },
                    {
                        title: 'New',
                        path: '/admin/contact/new',
                        name: 'contact_new',
                        iconClass: '',
                        active: false,
                        children: []
                    }
                ]
            }
        ]
    }
);

const RemoteMenuItems: Array<MenuItem> = new Array<MenuItem>(
    {
        title: 'Overview',
        path: '',
        name: 'remote',
        iconClass: 'info-circle',
        active: false,
        children: []
    },
    {
        title: 'File Manager',
        path: '',
        name: 'file_manager',
        iconClass: 'folder-open',
        active: false,
        children: [
            {
                title: '/root',
                path: '',
                name: 'file_manager_root',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: '/etc',
                path: '',
                name: 'file_manager_etc',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: '/var',
                path: '',
                name: 'file_manager_var',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: '/home',
                path: '',
                name: 'file_manager_home',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: 'Core.Device.Node.Solace',
                path: '',
                name: 'file_manager_node',
                iconClass: '',
                active: false,
                children: []
            },
            {
                title: 'Core.Device.TelitAPI.Service',
                path: '',
                name: 'file_manager_telit',
                iconClass: '',
                active: false,
                children: []
            }
        ]
    },
    {
        title: 'Terminal',
        path: '',
        name: 'terminal',
        iconClass: 'terminal',
        active: false,
        children: []
    }
);