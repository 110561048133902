import Vue from 'vue';
import { UserManager } from './sso-client-ts/UserManager'
import { UserManagerSettings } from './sso-client-ts/UserManagerSettings'
import { SolaceCredentials, User } from './sso-client-ts/User'
import IdentityConfig from '../models/identityConfig';


export default class SSOUserService {
    private mgr: UserManager;
    private user: User;
    private solace_credentials: SolaceCredentials;
    private config: UserManagerSettings;

    constructor() {
        this.config = Vue.UserManagerSettings;

        this.constructManager();
    }

    private constructManager() {
        if (this.mgr == null || this.mgr == undefined) {
            this.mgr = new UserManager(this.config);

            let self = this;
            this.mgr.events.addUserSignedOut(function () {
                self.signIn();
            });

            this.mgr.events.addAccessTokenExpired(function () {
                function checkFocus() {
                    if (document.hasFocus()) {
                        self.updateConfig().then(function () {
                            self.signIn(window.location.href);
                        });
                    } else {
                        window.setTimeout(checkFocus, 2000); /* this checks the flag every 2 seconds*/
                    }
                }
                checkFocus();
            });

            this.mgr.solaceEvents.addSolaceCredentialsExpiring(function () {
                self.GetSolaceCredentials();
            });

            this.mgr.solaceEvents.addSolaceCredentialsExpired(function () {
                self.GetSolaceCredentials();
            });
        }
        else {
            this.mgr.settings.sealed_app_id = this.config.sealed_app_id;
        }
    }

    get User() { return this.user }

    get SolaceCredentials() { return this.solace_credentials }

    get ClientID() { return this.config.app_id }

    public isAuthenticated() { return (this.User !== null || this.User !== undefined) && this.User.expired === false; }

    public Login(redirectUrl?: string) {
        const self = this as SSOUserService;
        const promise = new Promise(function (resolve, reject) {
            self.GetUser(redirectUrl).then(function () {
                resolve(void 0);
            }).catch(function () {
                reject();
            });
        });
        return promise;
    }

    private updateConfig() {
        const self = this as SSOUserService;
        const url = '/api/Home';
        const options = {
            method: 'GET'
        };

        class Configuration {
            identityConfig: IdentityConfig;
            instrumentationKey: string;
        }

        return fetch(url, options)
                .then(response => response.json() as Promise<Configuration>)
                .then(data => {
                    self.config.sealed_app_id = data.identityConfig.sealedAppID;
                    self.constructManager();
                }).catch(function (err) {
                    Vue.Logger.LogError(err, false);
                });

    }

    private signIn(redirectUrl?: string) {
        const self = this as SSOUserService;
        const promise = new Promise(function (resolve, reject) {
            self.mgr.signinRedirect({ state: redirectUrl }).then(function () {
                resolve(void 0);
            }).catch(function (err) {
                Vue.Logger.LogError(err, false);
                reject();
            });
        });
        return promise;
    }

    public SignOut() {
        const self = this as SSOUserService;
        this.mgr.signoutRedirect().then(function (resp) {
            console.log("signed out", resp);
        }).catch(function (err) {
            Vue.Logger.Log(err, false)
        })
    }

    public GetUser(redirectUrl?: string) {
        const self = this as SSOUserService;
        const promise = new Promise(function (resolve, reject) {
            self.mgr.getUser().then(function (user) {
                if (user === null || user.expired) {
                    self.signIn(redirectUrl).then(function () {
                        resolve(void 0);
                    }).catch(function () {
                        reject();
                    });
                } else {
                    self.user = user;
                    const userContext = user.profile.name.replace(/[,;=| ]+/g, "_");
                    Vue.appInsights.setAuthenticatedUserContext(userContext, null, true);
                    self.GetSolaceCredentials();
                    resolve(void 0);
                }
            }).catch(function (err) {
                Vue.Logger.LogError(err, false);
                reject();
            });
        });
        return promise;
    }

    public GetSolaceCredentials() {
        const self = this as SSOUserService;
        const promise = new Promise(function (resolve, reject) {
            self.mgr.getSolaceCredentials().then(function (credentials) {
                self.solace_credentials = credentials;
                resolve(void 0);
            }).catch(function (err) {
                Vue.Logger.LogError(err, false);
                reject();
            });
        });
        return promise;
    }

    public clearState() {
        this.mgr.clearStaleState().then(function () {
            Vue.Logger.Log("clearStaleState success", false);
        }).catch(function (e) {
            Vue.Logger.LogError("clearStaleState error: " + e.message, false);
        });
    }
}

