import { RemoteSession, RemoteSessionState } from '../types'
import { GetterTree, MutationTree } from 'vuex'

const state: RemoteSessionState = {
    remoteSessions: new Array<RemoteSession>()
}

const getters: GetterTree<RemoteSessionState, any> = {
    getRemoteSession: state => (deviceId: string) => {
        return state.remoteSessions.find(s => s.deviceId == deviceId);
    }
}

const mutations: MutationTree<RemoteSessionState> = {
    addRemoteSession(state: RemoteSessionState, data: RemoteSession) {
        state.remoteSessions.push(data as RemoteSession);
    },
    removeRemoteSession(state: RemoteSessionState, data: RemoteSession) {
        var index = state.remoteSessions.findIndex(s => s.deviceId == data.deviceId && s.sessionId == data.sessionId);
        state.remoteSessions.splice(index, 1);
    }
}

export default {
    state,
    getters,
    mutations
}