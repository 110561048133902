import { Buffer } from 'buffer'
export default class MessageReader {
    //ReadNextInt32: () => number;
    private readonly _payload: string;
    private _position: number = 0;
    constructor(payload: Uint8Array | string | null) {
        if (typeof payload === 'string') {
            this._payload = payload;      
        }
        else {
            this._payload = Buffer.from(payload).toString('latin1');
        }
    }
    ReadNext(token: string = null): string {
        if (token == null) {
            let oldPosition: number = this._position;
            this._position = this._payload.length;
            return this._payload.substring(oldPosition);
        }
        let index: number = this._payload.indexOf(token, this._position);
        if (index != -1) {
            let oldPosition: number = this._position;
            this._position = index + token.length;
            return this._payload.substring(oldPosition, index);
        }
        else {
            throw new Error(`The token '${token}' was not found in the payload`);
        }
    }
    HasNext() : boolean
        {
        return this._position < this._payload.length;
    }
    ReadNextDateTime(token: string = null): Date{
        return new Date(this.ReadNext(token));
    }
    ReadNextInt32(token: string = null): number {
        return Number.parseInt(this.ReadNext(token));
    }
    ReadNextDecimal(token: string = null): number {
        return Number.parseFloat(this.ReadNext(token));
    }
}