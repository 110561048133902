import { ComponentTypeState, ComponentType } from '../types'
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from 'models/apiResponse'


const state: ComponentTypeState = {
    list: [],
    timestampMS: null
}

const getters: GetterTree<ComponentTypeState, any> = {
    getComponentTypeList: state => () => {
        return state.list;
    },
    getComponentType: state => (componentTypeID: number) => {
        return state.list.find(f => f.component_type_id === componentTypeID);
    }
}

const mutations: MutationTree<ComponentTypeState> = {
    setComponentTypeList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((componentType: ComponentType, index) => {
            Vue.set(state.list, index, componentType);
        });
    },
    setComponentTypeTimestamp(state) {
        // 3 hours
        const expiration = 3 * 60 * 60 * 1000;
        state.timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<ComponentTypeState, any> = {
    async fetchComponentType({ commit, state, dispatch }, { componentTypeID }) {
        if (!state.list.find(f => f.component_type_id === componentTypeID)) {
            await dispatch('refreshComponentTypes');
        }
    },

    async fetchComponentTypes({ commit, state, dispatch }) {
            if (state.list.length === 0 || (state.timestampMS && new Date().getTime() > state.timestampMS)) {
                await dispatch('refreshComponentTypes');
            }
    },
    async refreshComponentTypes({ commit, state }) {
        const url = Vue.IdentityConfig.legacyApiUri + "/ota/componenttypes";
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "no-cache"
        }
        await fetch(url, options)
            .then(response => response.json() as Promise<ApiResponse<ComponentType>>)
            .then(data => {
                if (data.result) {
                    commit('setComponentTypeList', data.result);
                    commit('setComponentTypeTimestamp');
                }
                if (data.errors) {
                    data.errors.forEach(e => Vue.Logger.LogError(e.message));
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}