import { TagTypeState, TagType } from '../types'
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from 'models/apiResponse'


const state: TagTypeState = {
    list: [],
    timestampMS: null
}

const getters: GetterTree<TagTypeState, any> = {
    getTagTypeList: state => () => {
        return state.list;
    }
}

const mutations: MutationTree<TagTypeState> = {
    setTagTypeList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((tagType: TagType, index) => {
            Vue.set(state.list, index, tagType);
        });
    },
    setTagTypeTimestamp(state) {
        // 24 hours
        const expiration = 24 * 60 * 60 * 1000;
        state.timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<TagTypeState, any> = {
    async fetchTagTypes({ commit, state, dispatch }) {
            if (state.list.length == 0 || (state.timestampMS && new Date().getTime() > state.timestampMS)) {
                await dispatch('refreshTagTypes');
            }
    },
    async refreshTagTypes({ commit, state }) {
        const url = Vue.IdentityConfig.legacyApiUri + "/remote/tagtypes";
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        await fetch(url, options)
            .then(response => response.json() as Promise<ApiResponse<TagType>>)
            .then(data => {
                if (data.result) {
                    commit('setTagTypeList', data.result);
                    commit('setTagTypeTimestamp');
                }
                if (data.errors) {
                    data.errors.forEach(e => Vue.Logger.LogError(e.message));
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}