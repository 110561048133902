import { NodeTypeState, NodeType } from '../types'
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from 'models/apiResponse'


const state: NodeTypeState = {
    list: [],
    timestampMS: null
}

const getters: GetterTree<NodeTypeState, any> = {
    getnodeTypeList: state => () => {
        return state.list;
    }
}

const mutations: MutationTree<NodeTypeState> = {
    setNodeTypeList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((nodeType: string, index) => {
            Vue.set(state.list, index, nodeType);
        });
    },
    setNodeTypeTimestamp(state) {
        // 3 hours
        var expiration = 3 * 60 * 60 * 1000;
        state.timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<NodeTypeState, any> = {
    async fetchNodeTypes({ commit, state, dispatch }) {
        if (state.list.length == 0 || (state.timestampMS && new Date().getTime() > state.timestampMS)) {
            await dispatch('refreshNodeTypes');
        }
    },
    async refreshNodeTypes({ commit, state }) {
        const data = ['Gateway', 'Relay', 'DMR', 'Tag'];
        commit('setNodeTypeList', data);
        commit('setNodeTypeTimestamp');
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}