import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

export default class SoftDevice {
    soft_device_id: string;
    name: string;
    version: string;
    description: string;
    soft_device_uri: string;
    chipset_id: string;
    created_by: string;
    created_date: string;
    deleted: boolean;

    constructor(softDevice: SoftDevice) {
        this.soft_device_id = softDevice.soft_device_id;
        this.name = softDevice.name;
        this.version = softDevice.version;
        this.description = softDevice.description;
        this.chipset_id = softDevice.chipset_id;
        this.created_by = softDevice.created_by;
        this.created_date = format(parseISO(softDevice.created_date), 'yyyy-MM-dd HH:mm');
    }
}