import { TerminalHistoryState } from '../types'
import { GetterTree, MutationTree } from 'vuex'

const state: TerminalHistoryState = {
    history: new Array<string>()
}

const getters: GetterTree<TerminalHistoryState, any> = {
    getTerminalHistory: state => () => {
        return state.history;
    }
}

const mutations: MutationTree<TerminalHistoryState> = {
    setTerminalHistory(state: TerminalHistoryState, data: Array<string>) {
        state.history = data.slice(0, 100);
    }
}

export default {
    state,
    getters,
    mutations
}