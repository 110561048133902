import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { Timezone, TimezoneState } from '../types'
import ApiResponse from 'models/apiResponse'


const state: TimezoneState = {
    list: [],
    offsetMinutes: "0",
    name: null,
    timestampMS: null,
    ianaName: null
}

const getters: GetterTree<TimezoneState, any> = {
    getOffsetMinutes: state => state.offsetMinutes
}

const mutations: MutationTree<TimezoneState> = {
    setTimezoneName(state, name) {
        state.name = name;
    },
    setIanaTimezoneName(state, ianaName) {
        state.ianaName = ianaName;
    },
    setTimezoneList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((tz: Timezone, index) => {
            Vue.set(state.list, index, tz);
        });
    },
    setTimezoneTimestamp(state) {
        // 3 hours
        const expiration = 3 * 60 * 60 * 1000;
        state.timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<TimezoneState, any> = {
    async fetchTimezones({ commit, state }) {
        if (Vue.UserService && Vue.UserService.isAuthenticated && (state.list.length == 0 || (state.timestampMS && new Date().getTime() > state.timestampMS))) {
            const url = Vue.IdentityConfig.legacyApiUri + "/timezones";
            const options: RequestInit = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                cache: "no-cache"
            };
            await fetch(url, options)
                .then(response => response.json() as Promise<ApiResponse<Timezone>>)
                .then(data => {
                    if (data.result) {
                        commit('setTimezoneList', data.result);
                        commit('setTimezoneTimestamp');
                    }
                    if (data.errors) {
                        data.errors.forEach(e => Vue.Logger.LogError(e.message));
                    }
                });
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}