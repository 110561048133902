import { DeviceTypeState, DeviceType } from '../types'
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from 'models/apiResponse'


const state: DeviceTypeState = {
    list: [],
    timestampMS: null
}

const getters: GetterTree<DeviceTypeState, any> = {
    getDeviceTypeList: state => () => {
        return state.list;
    }
}

const mutations: MutationTree<DeviceTypeState> = {
    setDeviceTypeList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((deviceType: DeviceType, index) => {
            Vue.set(state.list, index, deviceType);
        });
    },
    setDeviceTypeTimestamp(state) {
        // 3 hours
        const expiration = 3 * 60 * 60 * 1000;
        state.timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<DeviceTypeState, any> = {
    async fetchDeviceTypes({ commit, state, dispatch }) {
            if (state.list.length == 0 || (state.timestampMS && new Date().getTime() > state.timestampMS)) {
                await dispatch('refreshDeviceTypes');
            }
    },
    async refreshDeviceTypes({ commit, state }) {
        const url = Vue.IdentityConfig.legacyApiUri + "/remote/devicetypes";
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "no-cache"
        };
        await fetch(url, options)
            .then(response => response.json() as Promise<ApiResponse<DeviceType>>)
            .then(data => {
                if (data.result) {
                    commit('setDeviceTypeList', data.result);
                    commit('setDeviceTypeTimestamp');
                }
                if (data.errors) {
                    data.errors.forEach(e => Vue.Logger.LogError(e.message));
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}