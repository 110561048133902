import { ComponentState } from '../types'
import Component from '../../models/mesh/ota/component'
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from 'models/apiResponse'

//Get an Component from a Vue component by calling this.$store.state.Component.list;

const state: ComponentState = {
    list: [],
    timestampMS: null
}

const getters: GetterTree<ComponentState, any> = {
    getComponentList: state => () => {
        return state.list;
    },
    getComponent: state => (Id: number) => {
        return state.list.find(f => f.id == Id);
    }
}

const mutations: MutationTree<ComponentState> = {
    setComponentList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((Component: Component, index) => {
            Vue.set(state.list, index, Component);
        });
    },
    setComponentTimestamp(state) {
        // 24 hours
        const expiration = 24 * 60 * 60 * 1000;
        state.timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<ComponentState, any> = {
    async fetchComponent({ commit, state, dispatch }, componentId) {
        if (componentId && !state.list.find(f => f.id == componentId)) {
            await dispatch('refreshComponents');
        }
    },

    async fetchComponents({ commit, state, dispatch }) {
        if (state.list.length == 0 || (state.timestampMS && new Date().getTime() > state.timestampMS)) {
            await dispatch('refreshComponents');
        }
    },
    async refreshComponents({ commit, state }) {
        const url = Vue.IdentityConfig.legacyApiUri + "/ota/components";
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "no-cache"
        };
        await fetch(url, options)
            .then(response => response.json() as Promise<ApiResponse<Component[]>>)
            .then(data => {
                if (data.result && data.result.length > 0) {
                    const Components = [];
                    data.result.forEach(f => {
                        Components.push(new Component(f));
                    })
                    commit('setComponentList', Components);
                    commit('setComponentTimestamp');
                }
                if (data.errors) {
                    data.errors.forEach(e => Vue.Logger.LogError(e.message));
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}