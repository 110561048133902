import Vue from 'vue'
function scrollHorizontally(e) {
    e = window.event || e;
    Vue.FastDom.measure(() => {
        const scrollLeft = this.scrollLeft;
        Vue.FastDom.mutate(() => {
            this.scrollLeft = scrollLeft + (e.deltaY || e.detail);
        })
    })
}

export var directive = {
    bind: function (el, binding) {
        if (!el) {
            return;
        }

        if (el.addEventListener) {
            el.addEventListener('mousewheel', scrollHorizontally, { passive: true });
            el.addEventListener('DOMMouseScroll', scrollHorizontally, { passive: true });
        } else {
            el.attachEvent('onmousewheel', scrollHorizontally);
        }
    },
    unbind: function (el, binding) {
        if (el.removeEventListener) {
            el.removeEventListener('mousewheel', scrollHorizontally, { passive: true });
            el.removeEventListener('DOMMouseScroll', scrollHorizontally, { passive: true });
        } else {
            el.detachEvent('onmousewheel', scrollHorizontally);
        }
    }
};

export var mixin = {
    directives: { onTablescroll: directive },
};
