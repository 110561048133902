export default class Country {
    name: string;
    name_sort: string;
    continent: string;
    region: string;
    postal: string;
    geounit: string;

    constructor(country: Country = null) {
        if (country) {
            this.name = country.name;
            this.name_sort = country.name_sort;
            this.continent = country.continent;
            this.region = country.region;
            this.postal = country.postal;
            this.geounit = country.geounit;
        }
    }
}