import { FirmwareStatusState, FirmwareStatus } from '../types'
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from 'models/apiResponse'


const state: FirmwareStatusState = {
    list: [],
    timestampMS: null
}

const getters: GetterTree<FirmwareStatusState, any> = {
    getFirmwareStatusList: state => () => {
        return state.list;
    }
}

const mutations: MutationTree<FirmwareStatusState> = {
    setFirmwareStatusList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((firmwareStatus: FirmwareStatus, index) => {
            Vue.set(state.list, index, firmwareStatus);
        });
    },
    setFirmwareStatusTimestamp(state) {
        // 24 hours
        const expiration = 24 * 60 * 60 * 1000;
        state.timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<FirmwareStatusState, any> = {
    async fetchFirmwareStatus({ commit, state, dispatch }) {
            if (state.list.length == 0 || (state.timestampMS && new Date().getTime() > state.timestampMS)) {
                await dispatch('refreshFirmwareStatus');
            }
    },
    async refreshFirmwareStatus({ commit, state }) {
        const url = Vue.IdentityConfig.legacyApiUri + "/remote/firmwarestatus";
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "no-cache"
        };
        await fetch(url, options)
            .then(response => response.json() as Promise<ApiResponse<FirmwareStatus>>)
            .then(data => {
                if (data.result) {
                    commit('setFirmwareStatusList', data.result);
                    commit('setFirmwareStatusTimestamp');
                }
                if (data.errors) {
                    data.errors.forEach(e => Vue.Logger.LogError(e.message));
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}