import { TodoState } from '../types'
import { GetterTree, MutationTree } from 'vuex'

const state: TodoState = {
    canSee: true,
    todos: [
        { text: 'Do something', checked: false },
        { text: 'Do something else', checked: true },
        { text: 'Do something tomorrow', checked: true }
    ]
}

const getters: GetterTree<TodoState, any> = {
    todos: state => state.todos.filter(todo => !todo.checked),
    done:  state => state.todos.filter(todo => todo.checked)
}

const mutations: MutationTree<TodoState> = {
    addTodo(state, newTodo) {
        const todoCopy = Object.assign({}, newTodo);
        state.todos.push(todoCopy);
    }
}

//const actions: ActionTree<TodoState, any> = {

//}

export default {
    state,
    getters,
    mutations
}