export default class SubLocation {
    sub_location: string;
    ouid: string;
    location: string;

    constructor(subLocation: SubLocation = null) {
        if (subLocation) {
            this.sub_location = subLocation.sub_location;
            this.ouid = subLocation.ouid;
            this.location = subLocation.location;
        }
    }
}