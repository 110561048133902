export default class ProblemDetails {
    detail?: string;
    instance?: string;
    status: number;
    title: string;
    type?: string;
    errors?: [string, string[]][]

    static ErrorMessages(data: ProblemDetails)
    {
        if (!data.errors) {
            if (data.detail)
                return [data.detail];
            else return [data.status.toString() +': '+ data.title];

        }

        return Object.entries(data.errors).flatMap(([key, value]) => {
            return (<Array<string>>value)
        });
    }
}