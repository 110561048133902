import { Vue, Component } from 'vue-property-decorator';
import { User } from 'services/sso-client-ts/User'

@Component
export default class LoginStatusComponent extends Vue {
    user: User = null;

    get username(): string {
        return this.user ? this.user.profile.name : '';
    }

    created() {
        this.DeferUser(this);
    }

    DeferUser(self) {
        if (Vue.UserService && Vue.UserService.User !== undefined) {
            self.user = Vue.UserService.User;
        }
        else {
            window.setTimeout(function () { self.DeferUser(self) }, 50);
        }
    };

    logout() {
        Vue.UserService.SignOut();
    }

    mounted() {
        var self = this;
        this.$store.subscribe(
            function (mutation, state) {
                if (mutation.type == 'setIsAuthenticated') {
                    if (mutation.payload == true && Vue.UserService.User != null) {
                        self.user = Vue.UserService.User;
                    }
                }
            }
        )
    }  

    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
    }

    hideDropdown() {
        this.showDropdown = false;
    }

    showDropdown: boolean = false;
}