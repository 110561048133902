import { ManufacturingModelState } from '../types'
import ManufacturingModel from 'models/manufacturing/manufacturingModel';
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ProblemDetails from 'models/problemDetails'


const state: ManufacturingModelState = {
    list: Array<ManufacturingModel>(),
    timestampMS: null
}

const getters: GetterTree<ManufacturingModelState, any> = {
    getManufacturingModelList: state => () => {
        return state.list;
    }
}

const mutations: MutationTree<ManufacturingModelState> = {
    setManufacturingModelList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((manufacturingModel: ManufacturingModel, index) => {
            Vue.set(state.list, index, manufacturingModel);
        });
    },
    setManufacturingModelTimestamp(state) {
        // 1 Hour
        const expiration = 60 * 60 * 1000;
        state.timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<ManufacturingModelState, any> = {
    async fetchManufacturingModels({ commit, state, dispatch }) {
        if (state.list.length == 0 || (state.timestampMS && new Date().getTime() > state.timestampMS)) {
            await dispatch('refreshManufacturingModels');
        }
    },
    async refreshManufacturingModels({ commit, state }) {
        const url = Vue.IdentityConfig.manufacturingApiUri + "/models";
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "no-cache"
        };
        await fetch(url, options)
            .then(response => {
                if (!response.ok)
                    throw response;
                return response.json() as Promise<Array<ManufacturingModel>>
            })
            .then(async data => {
                commit('setManufacturingModelList', data);
                commit('setManufacturingModelTimestamp');
            })
            .catch(error => {
                if (error instanceof Response) {
                    error.json().then((data: ProblemDetails) => {
                        ProblemDetails.ErrorMessages(data).forEach(e => {
                            Vue.Logger.LogError(e);
                        })
                    })
                } else {
                    Vue.Logger.LogError(error);
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}