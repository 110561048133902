<template>
    <div class="paginate-container">
        <paginate-links
                        :for="forAttribute"
                        :limit="limit"
                        :simple="simple"
                        :step-links="stepLinks"
                        :show-step-links="showStepLinks"
                        :hide-single-page="hideSinglePage"
                        :classes="classes"
                        :async="async"
                        :container="container">
        </paginate-links>
        <span class="paginate-count" v-if="this.$refs.paginator">
            {{count}}
        </span>
    </div>
</template>
<script>
    import { warn } from 'vue-paginate/src/util/debug'

    export default {
        name: 'paginate-links-count',
        props: {
            for: {
                type: String,
                required: true
            },
            limit: {
                type: Number,
                default: 0
            },
            simple: {
                type: Object,
                default: null,
            },
            stepLinks: {
                type: Object,
                default: null
            },
            showStepLinks: {
                type: Boolean
            },
            hideSinglePage: {
                type: Boolean
            },
            classes: {
                type: Object,
                default: null
            },
            async: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            forAttribute() {
                return this.for;
            },
            paginate () {
                return this.$parent.paginate;
            },
            $children() {
                return this.$parent.$children;
            },
            $refs() {
                return this.$parent.$refs;
            },
            count() {
                if (!this.$refs.paginator) {
                    return '';
                }
                const l = this.$refs.paginator.list.length;
                return `Viewing ${l} result` + (l !== 1 && 's' || '');
            }
        },
        mounted () {
            if(!this.$refs.paginator){
                warn(`<paginate> must have a 'ref="paginator"' attribute to display the item count.`, this.$parent, 'error')

            }
        }
    }
</script>