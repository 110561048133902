import { UserState } from '../types'
import { MutationTree } from 'vuex'

const state: UserState = {
    username: null,
    isAuthenticated: false
}

const mutations: MutationTree<UserState> = {
    setUsername(state, username) {
        state.username = username;
    },
    setIsAuthenticated(state, authenticated) {
        state.isAuthenticated = authenticated;
    }
}

export default {
    state,
    mutations
}