
    import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
    import { LoaderState } from 'store/types';

    @Component({
        components: {
            MenuNav: require('components/layout/navmenu/navmenu.vue.html').default,
            LoginStatus: require('components/layout/loginStatus/login_status.vue.html').default,
            RemoteComponent: () => import(/* webpackChunkName: "remote_component" */'components/areas/remote/remote.vue.html'),
            Loader: () => import(/* webpackChunkName: "loader" */'components/layout/loader/loader.vue.html'),
            Timezones: () => import(/* webpackChunkName: "timezones" */'components/layout/timezone/timezone.vue.html'),
            RemoteModal: () => import(/* webpackChunkName: "remote_modal" */'components/controls/remotemodal/remotemodal.vue.html')
        }
    })

    export default class AppComponent extends Vue {
        environment: string = '';
        showMenu: boolean = true;
        menuItemParent: boolean = true;
        showLoader: boolean = false;
        loaderText: string = '';
        signedIn: boolean = false;
        showRemoteModal: boolean = false;

        solaceStatus: boolean = true;
        context: any = null;
        subscriptions: any = null;

        hideRemoteModal() {
            this.$store.commit('showRemoteModal', false);
        }

        toggleMenu() {
            this.showMenu = !this.showMenu;
        }

        toggleMenuItemParent() {
            this.menuItemParent = !this.menuItemParent;
        }

        get pageClass(): string {
            if (this.showMenu)
                return '';
            else {
                return 'menu-hidden';
            }
        }

        get isRemoteZone() {
            return this.$route.matched.some(m => m.meta.zone == 'remote')
        }

        get environmentTitle(): string {
            switch (this.environment) {
                case "Local":
                    return "[Local Environment]"
                case "Development":
                    return "[Development Environment]"
                case "Test":
                    return "[Test Environment]"
                case "Production":
                    return ""
                default:
                    return "";
            }
        }

        mounted() {
            var self = this;
            this.environment = Vue.IdentityConfig.environment;
            this.$store.subscribe(
                function (mutation, state) {
                    if (mutation.type == 'showLoader') {
                        self.showLoader = mutation.payload;
                        self.loaderText = '';
                    }
                    if (mutation.type == 'showLoaderWithText') {
                        self.showLoader = (<LoaderState>mutation.payload).showLoaderBar;
                        self.loaderText = (<LoaderState>mutation.payload).loaderText;
                    }
                    if (mutation.type == 'setIsAuthenticated') {
                        if (mutation.payload == true && Vue.UserService.User != null) {
                            self.signedIn = true;

                            window.setTimeout(() => {
                                if (!Vue.Solace.Started) {
                                    self.solaceStatus = false;
                                }
                                if (!self.context) {
                                    var methods = new Map<string, (...args: any[]) => void>();
                                    methods.set("sessionStarted", () => { self.solaceStatus = true; self.$forceUpdate(); });
                                    methods.set("sessionStopped", () => { self.solaceStatus = false; self.$forceUpdate(); });
                                    self.context = Vue.Solace.On(methods);
                                }
                                if (!self.subscriptions) {
                                    var methods = new Map<string, (...args: any[]) => void>();
                                    methods.set("DEVICECHANGED/>", async () => {
                                        await self.$store.dispatch('refreshSubLocations');
                                        self.$forceUpdate();
                                    });
                                    methods.set("OUCHANGED", async () => {
                                        await self.$store.dispatch('refreshOUs');
                                        self.$forceUpdate();
                                    });
                                    self.subscriptions = Vue.Solace.Subscribe(methods);
                                }
                            }, 4000);
                        } else {
                            self.signedIn = false;
                        }
                    }
                    if (mutation.type == 'showRemoteModal') {
                        self.showRemoteModal = mutation.payload;
                    }
                }
            )

            this.$root.$on("resize", () => {
                this.checkScroll();
            });
        }

        updated() {
            this.checkScroll();
        }
        checkScroll() {
            Vue.FastDom.measure(() => {
                const scrollHeight = $('html').get(0).scrollHeight;
                const clientHeight = $('html').get(0).clientHeight;
                Vue.FastDom.mutate(() => {
                    if (scrollHeight > clientHeight) {
                        $('.page-container').addClass('scroll-visible');
                    }
                    else {
                        $('.page-container').removeClass('scroll-visible');
                    }
                })
            })
        }
    }
