import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import Firmware from './firmware';

export default class FirmwareModel {
    firmware_model_id: number;
    firmware_id: number;
    firmware_status_id: FirmwareStatusCode;
    comment: string;
    created_by: string;
    created_date: string;

    major_version: number;
    minor_version: number;
    model_no: string;
    soft_device_name: string;
    description: string;
    history: FirmwareModel[] = Array<FirmwareModel>();
    showHistory: boolean = false;
    has_deployments: boolean;

    get firmware_status() {
        return FirmwareStatusCode[this.firmware_status_id];
    }

    get version(): string {
        return this.major_version + '.' + this.minor_version;
    }

    constructor(firmwareModel: FirmwareModel = null, firmware: Firmware = null) {
        if (firmwareModel) {
            this.firmware_model_id = firmwareModel.firmware_model_id;
            this.firmware_id = firmwareModel.firmware_id;
            this.firmware_status_id = firmwareModel.firmware_status_id;
            this.major_version = firmwareModel.major_version || (firmware && +firmware.major_version);
            this.minor_version = firmwareModel.minor_version || (firmware && +firmware.minor_version);
            this.created_by = firmwareModel.created_by || '';
            this.description = firmwareModel.description || (firmware && firmware.description) || '';
            this.created_date = format(parseISO(firmwareModel.created_date), 'yyyy-MM-dd HH:mm');
            this.comment = firmwareModel.comment || '';
            this.model_no = firmwareModel.model_no || '';
            this.soft_device_name = firmwareModel.soft_device_name || '';
            this.has_deployments = firmwareModel.has_deployments || (firmware && firmware.has_deployments);

            if (firmwareModel.history)
                firmwareModel.history.forEach((item) => {
                    this.history.push(new FirmwareModel(item));
                });
        }
    }

    get sortversion(): number {
        if (this.version) {
            var e: number = 0;
            this.version.split('.').reverse().forEach(function (v, i) {
                return e += +v << (i * 8);
            });
            return e;
        }
    }
}

export enum FirmwareStatusCode {
    "" = 0,
    "New" = 1,
    "Test Passed" = 2,
    "Test Failed" = 3,
    "Approved" = 4,
    "Retired" = 5
}