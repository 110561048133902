import { LookupCodeState, LookupCode } from '../types'
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from '../../models/apiResponse'
import Common from '../../utils/common';

class LookupTable {
    list: LookupCode[];
    timestampMS: number;

    constructor() {
        this.list = [];
    }
}

const state: LookupCodeState = {
    tables: {}
}

const getters: GetterTree<LookupCodeState, any> = {
    getLookupCodesList: state => (lookupTableName) => {
        return state.tables[lookupTableName].list;
    }
}

const mutations: MutationTree<LookupCodeState> = {
    setLookupCodesList(state, payload) {
        //reset list first.
        state.tables[payload.lookupTableName] = new LookupTable();
        payload.data.forEach((lookupCode: LookupCode, index) => {
            Vue.set(state.tables[payload.lookupTableName].list, index, lookupCode);
        });
    },
    setLookupCodesTimestamp(state, lookupTableName) {
        // 15 Mins
        var expiration = 15 * 60 * 1000;
        state.tables[lookupTableName].timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<LookupCodeState, any> = {
    async fetchLookupCodes({ commit, state, dispatch }, lookupTableName) {
        var table = state.tables[lookupTableName];
        if (!table || table.list.length == 0 || (table.timestampMS && new Date().getTime() > table.timestampMS)) {
            await dispatch('refreshLookupCodes', lookupTableName);
            }
    },
    async refreshLookupCodes({ commit, state }, lookupTableName: string) {
        var params = {
            TableName: lookupTableName,
        };
        var url = Common.BuildApiUrlWithParams(Vue.IdentityConfig.legacyApiUri + "/lookups", params);
        var options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "no-cache"
        }
        await fetch(url, options)
            .then(response => response.json() as Promise<ApiResponse<LookupCodeState>>)
            .then(data => {
                if (data.result) {
                    commit('setLookupCodesList', { lookupTableName: lookupTableName, data: data.result });
                    commit('setLookupCodesTimestamp', lookupTableName);
                }
                if (data.errors) {
                    data.errors.forEach(e => Vue.Logger.LogError(e.message));
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}