import parseISO from 'date-fns/parseISO';
import add from 'date-fns/add';
import startOfHour from 'date-fns/startOfHour';
import { utcToZonedTime, formatInTimeZone, format } from 'date-fns-tz';
export default class DatetimeConverter {
    static ToUtcDatetime(dt: string, formatString = 'yyyy-MM-dd HH:mm:ss'): string {
        if (dt) {
            const utc = utcToZonedTime(parseISO(dt), "Etc/UTC");
            return format(utc, formatString);
        }
        else
            return '';
    }

    static ToSelectedTimezoneDatetime(dt: string, ianaTimeZoneName: string, formatString = 'yyyy-MM-dd HH:mm:ss'): string {
        if (dt && ianaTimeZoneName && dt !== 'Invalid date') {
            if (ianaTimeZoneName)
                return formatInTimeZone(dt, ianaTimeZoneName, formatString);
            else
                return format(parseISO(dt),formatString);
        }
        else
            return '';
    }

    static GetUTCDatetimePlusHours(hours = 0) {
        const date = startOfHour(add(new Date(), { hours: hours })).toISOString();
        return date;
    }

    static ToPreviousWholeHour(dt: string) {
        return startOfHour(parseISO(dt)).toISOString();
    }

    static ToNextWholeHour(dt: string) {
        return startOfHour(add(parseISO(dt), {hours:1})).toISOString();
    }
}