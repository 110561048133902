import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import createMutationsSharer from "vuex-shared-mutations";
import todos from './modules/todos'
import loader from './modules/loader'
import sort from './modules/sort'
import ou from './modules/ou'
import timezone from './modules/timezone'
import user from './modules/user'
import remoteSession from './modules/remoteSession'
import connection from './modules/connection'
import firmware from './modules/firmware'
import softDevice from './modules/softDevice'
import bootloader from './modules/bootloader'
import fieldState from './modules/fieldState'
import remoteModal from './modules/remoteModal'
import tagFirmware from './modules/tagFirmware'
import subLocation from './modules/subLocation'
import deviceModel from './modules/deviceModel'
import deviceType from './modules/deviceType'
import tagType from './modules/tagType'
import lookup from './modules/lookup'
import firmwareStatus from './modules/firmwareStatus'
import packageApprovalStatus from './modules/packageApprovalStatus'
import tagBootloader from './modules/tagBootloader'
import componentType from './modules/componentType'
import certificateType from './modules/certificateType'
import component from './modules/component'
import terminalHistory from './modules/terminalHistory'
import country from './modules/country'
import state from './modules/state'
import nodeType from './modules/nodeType'
import job from './modules/job'
import manufacturingModel from './modules/manufacturingModel'
import manufacturingEventCategory from './modules/manufacturingEventCategory'
import manufacturingEventName from './modules/manufacturingEventName'
import manufacturingQAMetric from './modules/qaMetric'

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        todos,
        loader,
        sort,
        timezone,
        ou,
        user,
        remoteSession,
        connection,
        firmware,
        softDevice,
        bootloader,
        fieldState,
        remoteModal,
        tagFirmware,
        subLocation,
        deviceModel,
        deviceType,
        tagType,
        lookup,
        firmwareStatus,
        packageApprovalStatus,
        tagBootloader,
        componentType,
        certificateType,
        component,
        terminalHistory,
        country,
        state,
        nodeType,
        job,
        manufacturingModel,
        manufacturingEventCategory,
        manufacturingEventName,
        manufacturingQAMetric
    },
    plugins: [
        new VuexPersistence({
            key: "vuex-ou",
            modules: ['ou']
        }).plugin,
        new VuexPersistence({
            key: "vuex-timezone",
            modules: ['timezone']
        }).plugin,
        new VuexPersistence({
            key: "vuex-fieldstate",
            modules: ['fieldState']
        }).plugin,
        new VuexPersistence({
            key: "vuex-devicemodel",
            modules: ['deviceModel']
        }).plugin,
        new VuexPersistence({
            key: "vuex-devicetype",
            modules: ['deviceType']
        }).plugin,
        new VuexPersistence({
            key: "vuex-tagtype",
            modules: ['tagType']
        }).plugin,
        new VuexPersistence({
            key: "vuex-sublocation",
            modules: ['subLocation']
        }).plugin,
        new VuexPersistence({
            key: "vuex-lookup",
            modules: ['lookup']
        }).plugin,
        new VuexPersistence({
            key: "vuex-firmwarestatus",
            modules: ['firmwareStatus']
        }).plugin,
        new VuexPersistence({
            key: "vuex-packageapprovalstatus",
            modules: ['packageApprovalStatus']
        }).plugin,
        new VuexPersistence({
            key: "vuex-firmwarepackage",
            modules: ['firmwarePackage']
        }).plugin,
        new VuexPersistence({
            key: "vuex-componenttype",
            modules: ['componentType']
        }).plugin,
        new VuexPersistence({
            key: "vuex-certificatetype",
            modules: ['certificateType']
        }).plugin,
        new VuexPersistence({
            key: "vuex-terminalHistory",
            modules: ['terminalHistory']
        }).plugin,
        new VuexPersistence({
            key: "vuex-country",
            modules: ['country']
        }).plugin,
        new VuexPersistence({
            key: "vuex-state",
            modules: ['state']
        }).plugin,
        new VuexPersistence({
            key: "vuex-nodetype",
            modules: ['nodeType']
        }).plugin,
        new VuexPersistence({
            key: "vuex-job",
            modules: ['job']
        }).plugin,
        new VuexPersistence({
            key: "vuex-manufacturingModel",
            modules: ['manufacturingModel']
        }).plugin,
        new VuexPersistence({
            key: "vuex-manufacturingEventCategory",
            modules: ['manufacturingEventCategory']
        }).plugin,
        new VuexPersistence({
            key: "vuex-manufacturingEventName",
            modules: ['manufacturingEventName']
        }).plugin,
        new VuexPersistence({
            key: "vuex-manufacturingQAMetric",
            modules: ['manufacturingQAMetric']
        }).plugin,
        createMutationsSharer({
            predicate: [
                "setCountryList",
                "setCountryTimestamp",
                "setStateList",
                "setStateTimestamp,",
                "setSubLocationList",
                "setSubLocationTimestamp",
                "setDeviceModelList",
                "setDeviceModelTimestamp",
                "setDeviceTypeList",
                "setDeviceTypeTimestamp",
                "setFieldState",
                "setBootloaderList",
                "setFirmwareList",
                "setSoftDeviceList",
                "setFirmwareStatusList",
                "setFirmwareStatusTimestamp",
                "setLookupCodesList",
                "setLookupCodesTimestamp",
                "setOUList",
                "setOUTimestamp",
                "setPackageApprovalStatusList",
                "setPackageApprovalStatusTimestamp",
                "setTagFirmwareList",
                "setTagBootloaderList",
                "setTagTypeList",
                "setTagTypeTimestamp",
                "setTimezoneName",
                "setIanaTimezoneName",
                "setTimezoneList",
                "setTimezoneTimestamp",
                "setTerminalHistory",
                "setJobList",
                "setJobTimestamp",
            ]
        })
    ]
})