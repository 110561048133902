import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import FirmwareModel from './firmwareModel';

export default class Firmware {
    firmware_id: number;
    soft_device_id: string;
    major_version: string;
    minor_version: string;
    description: string;
    firmware_uri: string;
    soft_device_firmware_uri: string;
    firmware_sas_token: string;
    soft_device_firmware_sas_token: string;
    created_by: string;
    created_date: string;
    deleted: boolean;
    deleted_by: string;
    deleted_date: string;
    soft_device_name: string;
    models: Array<FirmwareModel>;
    has_deployments: boolean;

    get model_nos(): string {
        let modelNos = '';
        let firstRow = true;
        this.models.forEach(m => {
            if (firstRow) {
                modelNos += m.model_no;
                firstRow = false;
            }
            else {
                modelNos += ', ' + m.model_no;
            }
        })

        return modelNos;
    }

    get version(): string {
        return this.major_version + '.' + this.minor_version;
    }

    get sortversion(): number {
        if (this.version) {
            var e: number = 0;
            this.version.split('.').reverse().forEach(function (v, i) {
                return e += +v << (i * 8);
            });
            return e;
        }
    }

    model_no: string;

    constructor(firmware: Firmware = null) {
        if (firmware) {
            this.firmware_id = firmware.firmware_id;
            this.soft_device_id = firmware.soft_device_id;
            this.major_version = firmware.major_version;
            this.minor_version = firmware.minor_version;
            this.description = firmware.description || '';
            this.firmware_uri = firmware.firmware_uri || '';
            this.firmware_sas_token = firmware.firmware_sas_token;
            this.soft_device_firmware_uri = firmware.soft_device_firmware_uri || '';
            this.soft_device_firmware_sas_token = firmware.soft_device_firmware_sas_token;
            this.created_by = firmware.created_by || '';
            this.created_date = firmware.created_date && format(parseISO(firmware.created_date), 'yyyy-MM-dd HH:mm') || '';
            this.deleted = firmware.deleted || false;
            this.deleted_by = firmware.deleted_by || '';
            this.deleted_date = firmware.deleted_date && format(parseISO(firmware.deleted_date), 'yyyy-MM-dd HH:mm') || '';
            this.soft_device_name = firmware.soft_device_name || '';
            this.has_deployments = firmware.has_deployments || false;
            this.models = [];

            firmware.models.forEach(item => {
                this.models.push(new FirmwareModel(item, firmware));
            });
        }
    }
}

export class ModuleFirmware {
    scheduled: string;
    installed: string;
    serial_id: string;
    firmware_version: string;
}

