import Common from './common';

export function strLimit(input, limit) {
        if (!input || input.length <= limit) {
            return input;
        }
        return input.substring(0, limit) + '...';
}

export function iccidstrLimit(input, limit) {
    return Common.iccidstrLimit(input, limit)
}

export function formatBytes(input) {
   return Common.ToBytesReadable(input);
}

export function formatDate(input) {
    return Common.FormatDate(input);
}
