import { GetterTree, MutationTree } from 'vuex'
import { ConnectionState, Connection } from '../types'

const state: ConnectionState = {
    connections: new Array<Connection>()
}

const getters: GetterTree<ConnectionState, any> = {
    getConnection: state => (deviceId: string) => {
        return state.connections.find(c => c.deviceId == deviceId);
    }
}

const mutations: MutationTree<ConnectionState> = {
    addConnection(state: ConnectionState, data: Connection) {
        state.connections.push(data as Connection);
    },
    removeConnection(state: ConnectionState, deviceId: string) {
        var index = state.connections.findIndex((c) => c.deviceId == deviceId);
        if (index > -1)
            state.connections.splice(index, 1);
    }
}

export default {
    state,
    getters,
    mutations
}
