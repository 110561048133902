import { JobState } from '../types'
import Job from 'models/manufacturing/job';
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from 'models/apiResponse'
import Common from 'utils/common';
import ProblemDetails from '../../models/problemDetails';


const state: JobState = {
    list: [],
    timestampMS: null
}

const getters: GetterTree<JobState, any> = {
    getJobList: state => () => {
        return state.list;
    }
}

const mutations: MutationTree<JobState> = {
    setJobList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((job: Job, index) => {
            Vue.set(state.list, index, job);
        });
    },
    setJobTimestamp(state) {
        // 24 hours
        const expiration = 24 * 60 * 60 * 1000;
        state.timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<JobState, any> = {
    async fetchJobs({ commit, state, dispatch }) {
        if (state.list.length == 0 || (state.timestampMS && new Date().getTime() > state.timestampMS)) {
            await dispatch('refreshJobs');
        }
    },
    async refreshJobs({ commit, state }) {
        const params = {};
        const url = Common.BuildApiUrlWithParams(Vue.IdentityConfig.manufacturingApiUri + "/jobs", params);
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "no-cache"
        };
        await fetch(url, options)
            .then(response => {
                if (!response.ok)
                    throw response;
                return response.json() as Promise<Array<Job>>
            })
            .then(async data => {
                const Jobs = [];
                data.forEach(j => {
                    Jobs.push(new Job(j));
                })
                commit('setJobList', Jobs);
                commit('setJobTimestamp');
            })
            .catch(error => {
                if (error instanceof Response) {
                    error.json().then((data: ProblemDetails) => {
                        ProblemDetails.ErrorMessages(data).forEach(e => {
                            Vue.Logger.LogError(e);
                        })
                    })
                } else {
                    Vue.Logger.LogError(error);
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}