import solclientjs from 'solclientjs'

import Vue from 'vue';
import * as Events from '../models/solace/events'
import MessageReader from '../utils/MessageReader'
import Process from './process';

const connectionString = 'CLIENT_CLIENT_CONNECT';
const disconnectionString = 'CLIENT_CLIENT_DISCONNECT';
const connectionMQTTString = 'CLIENT_CLIENT_CONNECT_MQTT';
const disconnectionMQTTString = 'CLIENT_CLIENT_DISCONNECT_MQTT';

class Context {
    map: Map<string, (...args: any[]) => void>;
    subscribed: boolean;
    topics: Array<string>;
    fromSubscribe: boolean;

    constructor(methods: Map<string, (...args: any[]) => void>, fromSubscribe: boolean = true) {
        this.map = methods;
        this.subscribed = false;
        this.fromSubscribe = fromSubscribe;

        this.topics = new Array<string>();
        this.map.forEach((method: (...args: any[]) => void, topic: string) => {
            this.topics.push(topic);
        });
    }
}

class ContextManager {
    contextList: Array<Context>;
    subscribedTopics: Map<string, number>;
    topicMethods: Map<string, ((...args: any[]) => void)[]>;

    constructor() {
        this.contextList = new Array<Context>();
        this.subscribedTopics = new Map<string, number>();
        this.topicMethods = new Map<string, ((...args: any[]) => void)[]>();
    }

    AddContext(context: Context) {
        if (this.contextList.indexOf(context) == -1) {
            this.contextList.push(context);
            this.AddTopicMethods(context);

            if (context.fromSubscribe)
                this.AddSubscribedTopicContext(context);
        }
    }

    private AddTopicMethods(context: Context) {
        context.map.forEach((method, topic) => {
            if (method) {
                if (this.topicMethods.has(topic)) {
                    var methods = this.topicMethods.get(topic);
                    var index = methods.indexOf(method);
                    if (index == -1)
                        methods.push(method);
                } else {
                    this.topicMethods.set(topic, [method]);
                }
            }
        });
    }

    private AddSubscribedTopicContext(context: Context) {
        context.topics.forEach(topic => {
            if (this.subscribedTopics.has(topic)) {
                var value = this.subscribedTopics.get(topic);
                value++;
                this.subscribedTopics.set(topic, value);
            } else {
                this.subscribedTopics.set(topic, 1);
            }
        });
    }

    RemoveContext(context: Context) {
        var index = this.contextList.indexOf(context);
        if (index > -1) {
            this.contextList.splice(index, 1);
            this.RemoveTopicMethods(context);

            if (context.fromSubscribe)
                this.RemoveSubscribedTopicContext(context);
        }
        return index;
    }

    private RemoveTopicMethods(context: Context) {
        context.map.forEach((method, topic) => {
            if (method) {
                if (this.topicMethods.has(topic)) {
                    var methods = this.topicMethods.get(topic);
                    var index = methods.indexOf(method);
                    if (index > -1)
                        methods.splice(index, 1);
                }
            }
        });
    }

    private RemoveSubscribedTopicContext(context: Context) {
        context.topics.forEach(topic => {
            if (this.subscribedTopics.has(topic)) {
                var value = this.subscribedTopics.get(topic);
                value--;
                this.subscribedTopics.set(topic, value);
            }
        });
    }

    DeleteTopicFromSubscribedTopic(topic: string) {
        if (this.subscribedTopics.has(topic))
            this.subscribedTopics.delete(topic);
    }
}

class PendingRequest {
    correlationId: any;
    timer: any;
    replyReceivedCBFunction: any;
    reqFailedCBFunction: any;
    userObject: any;
    constructor(correlationId, timer, replyReceivedCBFunction, reqFailedCBFunction, userObject) {
        this.correlationId = correlationId;
        this.timer = timer;
        this.replyReceivedCBFunction = replyReceivedCBFunction;
        this.reqFailedCBFunction = reqFailedCBFunction;
        this.userObject = userObject;
    }
}

class SenderManager {
    pendingRequests: {};

    constructor() {
        this.pendingRequests = {};
    }

    sendRequest = function sendRequest(session, message,
        timeout,
        replyReceivedCBFunction,
        requestFailedCBFunction,
        userObject
    ) {
        if (timeout === void 0) timeout = undefined;
        if (replyReceivedCBFunction === void 0) replyReceivedCBFunction = undefined;
        if (requestFailedCBFunction === void 0) requestFailedCBFunction = undefined;
        if (userObject === void 0) userObject = undefined;
        const self = this;

        if (!message.getReplyTo()) {
            session.sendRequest(message,
                timeout,
                replyReceivedCBFunction,
                requestFailedCBFunction,
                userObject);
            return;
        }

        const replyTo = message.getReplyTo().toString();
        const correlationId = message.getCorrelationId();

        const timer = setTimeout(function () {
            try {
                const result = delete self.pendingRequests[replyTo];
                if (!result) {
                    Vue.Logger.LogError("Cannot delete data request " + replyTo, false);
                }
            } catch (e) {
                Vue.Logger.LogError("Cannot delete data request " + replyTo, false);
            }

            if (requestFailedCBFunction) {
                const requestEvent: solclientjs.SessionEvent = (solclientjs.SessionEvent as any).build(solclientjs.RequestEventCode.REQUEST_TIMEOUT,
                    'Request timeout',
                    correlationId);

                requestFailedCBFunction(self, requestEvent, userObject);
            }
        }, timeout + 100);

        this.pendingRequests[replyTo] = new PendingRequest(
            correlationId,
            timer,
            replyReceivedCBFunction,
            requestFailedCBFunction,
            userObject);

        session.sendRequest(message,
            timeout,
            (a, b) => { self.CancelPendingRequest(replyTo); replyReceivedCBFunction(a, b); },
            (a, b) => { self.CancelPendingRequest(replyTo); requestFailedCBFunction(a, b); },
            userObject);
    }

    IsPending(message: solclientjs.Message) {
        const destination = message.getDestination().toString();
        return this.pendingRequests.hasOwnProperty(destination);
    }

    ReplyCallback(message: solclientjs.Message) {
        const destination = message.getDestination().toString();
        const req = this.pendingRequests[destination];
        if (req === undefined || req === null) {
            return null;
        }
        this.CancelPendingRequest(destination);

        req.replyReceivedCBFunction(null, message);
    }

    CancelPendingRequest(destination) {
        const req = this.pendingRequests[destination];
        if (req === undefined || req === null) {
            return null;
        }

        if (req.timer) {
            clearTimeout(req.timer);
            req.timer = null;
        }

        try {
            const result = delete this.pendingRequests[destination];
            if (!result) {
                Vue.Logger.LogError("Cannot delete data request " + destination, false);
            }
        } catch (e) {
            Vue.Logger.LogError("Cannot delete data request " + destination, false);
        }
    }
}

export default class SolaceService {
    id: number = 0;
    session: solclientjs.Session;
    Started: boolean = false;

    private Contexts: ContextManager = new ContextManager();

    private Sender: SenderManager = new SenderManager();

    constructor() {
        const factoryProps = new solclientjs.SolclientFactoryProperties();
        factoryProps.profile = solclientjs.SolclientFactoryProfiles.version10;
        solclientjs.SolclientFactory.init(factoryProps);
        solclientjs.SolclientFactory.setLogLevel(solclientjs.LogLevel.ERROR);
        var host = Vue.UserService.SolaceCredentials.host;

        var url = 'ws://' + host + ':80';
        if (window.location.protocol.includes('https')) {
            url = 'wss://' + host + ':443';
        }

        this.session = solclientjs.SolclientFactory.createSession({
            url: url,
            vpnName: Vue.UserService.SolaceCredentials.vpnName,
            userName: Vue.UserService.SolaceCredentials.username,
            password: Vue.UserService.SolaceCredentials.password,
            clientName: this.GenerateClientName(Vue.UserService.User.profile.name),
            noLocal: true,
            connectRetries: -1,
            reconnectRetries: -1,
            reapplySubscriptions: true,
            publisherProperties: null
        });

        var clientConnectMQTTDestination: solclientjs.Destination = solclientjs.SolclientFactory.createTopicDestination("#LOG/INFO/CLIENT/*/CLIENT_CLIENT_CONNECT_MQTT/iot/>");
        var clientDisconnectMQTTDestination: solclientjs.Destination = solclientjs.SolclientFactory.createTopicDestination("#LOG/INFO/CLIENT/*/CLIENT_CLIENT_DISCONNECT_MQTT/iot/>");

        var clientConnectDestination: solclientjs.Destination = solclientjs.SolclientFactory.createTopicDestination("#LOG/INFO/CLIENT/*/CLIENT_CLIENT_CONNECT/iot/>");
        var clientDisconnectDestination: solclientjs.Destination = solclientjs.SolclientFactory.createTopicDestination("#LOG/INFO/CLIENT/*/CLIENT_CLIENT_DISCONNECT/iot/>");
        var sessionEventCode: typeof solclientjs.SessionEventCode = solclientjs.SessionEventCode;
        this.session.on(solclientjs.SessionEventCode.UP_NOTICE, (sessionEvent) => {
            this.sessionStarted();
            Vue.Logger.Log(sessionEvent, false);

            //subscribe to connect events
            try {
                this.session.subscribe(
                    clientConnectDestination,
                    true, // generate confirmation when subscription is added successfully
                    { correlationKey: "CLIENT_CONNECT" }, // use topic name as correlation key
                    10000 // 10 seconds timeout for this operation
                );
            } catch (error) {
                Vue.Logger.LogError(error, false);
            }

            //subscribe to disconnect events
            try {
                this.session.subscribe(
                    clientDisconnectDestination,
                    true, // generate confirmation when subscription is added successfully
                    { correlationKey: "CLIENT_DISCONNECT" }, // use topic name as correlation key
                    10000 // 10 seconds timeout for this operation
                );
            } catch (error) {
                Vue.Logger.LogError(error, false);
            }

            //subscribe to connect events
            try {
                this.session.subscribe(
                    clientConnectMQTTDestination,
                    true, // generate confirmation when subscription is added successfully
                    { correlationKey: "CLIENT_CONNECT_MQTT" }, // use topic name as correlation key
                    10000 // 10 seconds timeout for this operation
                );
            } catch (error) {
                Vue.Logger.LogError(error, false);
            }

            //subscribe to disconnect events
            try {
                this.session.subscribe(
                    clientDisconnectMQTTDestination,
                    true, // generate confirmation when subscription is added successfully
                    { correlationKey: "CLIENT_DISCONNECT_MQTT" }, // use topic name as correlation key
                    10000 // 10 seconds timeout for this operation
                );
            } catch (error) {
                Vue.Logger.LogError(error, false);
            }
        });

        this.session.on(sessionEventCode.DOWN_ERROR, (sessionEvent) => {
            this.sessionStopped();
            Vue.Logger.Log(sessionEvent, false);
        });

        this.session.on(sessionEventCode.CONNECT_FAILED_ERROR, (sessionEvent) => {
            this.sessionStopped();
            Vue.Logger.Log(sessionEvent, false);
        });

        this.session.on(sessionEventCode.SUBSCRIPTION_ERROR, (sessionEvent) => {
            Vue.Logger.Log(sessionEvent, false);
        });

        this.session.on(sessionEventCode.SUBSCRIPTION_OK, (sessionEvent) => {
            Vue.Logger.Log(sessionEvent, false);
        });

        this.session.on(sessionEventCode.DISCONNECTED, (sessionEvent) => {
            this.sessionStopped();
            Vue.Logger.Log(sessionEvent, false);
        });

        this.session.on(sessionEventCode.RECONNECTING_NOTICE, (sessionEvent) => {
            this.sessionStopped();
            Vue.Logger.Log(sessionEvent, false);
        });

        this.session.on(sessionEventCode.RECONNECTED_NOTICE, (sessionEvent) => {
            this.sessionStarted();
            Vue.Logger.Log(sessionEvent, false);
        });

        this.session.on(solclientjs.SessionEventCode.MESSAGE, (message: solclientjs.Message) => {
            try {
                // Need to change this look up the UserPropertyMap - MessageType
                var destination = message.getDestination();
                var parts = destination.getName().split("/");

                if (parts.includes(connectionString)) {
                    let messageReader = new MessageReader(message.getBinaryAttachment());
                    let msg = new Events.ClientConnect(messageReader);
                    Vue.Logger.Log(msg, false);

                    this.invokeClientMethod("connected", msg.ClientName);
                }
                else if (parts.includes(disconnectionString)) {
                    let messageReader = new MessageReader(message.getBinaryAttachment());
                    let msg = new Events.ClientDisconnect(messageReader);
                    Vue.Logger.Log(msg, false);
                    if (msg.DisconnectReason !== 'Forced Logout')
                        this.invokeClientMethod("disconnected", msg.ClientName);
                }
                else if (parts.includes(connectionMQTTString)) {
                    let messageReader = new MessageReader(message.getBinaryAttachment());
                    let msg = new Events.ClientConnectMQTT(messageReader);
                    Vue.Logger.Log(msg, false);

                    this.invokeClientMethod("connected", msg.ClientId);
                }
                else if (parts.includes(disconnectionMQTTString)) {
                    let messageReader = new MessageReader(message.getBinaryAttachment());
                    let msg = new Events.ClientDisconnectMQTT(messageReader);
                    Vue.Logger.Log(msg, false);
                    if (msg.DisconnectReason !== 'Forced Logout')
                        this.invokeClientMethod("disconnected", msg.ClientId);
                }
                else if (this.Sender.IsPending(message)) {
                    this.Sender.ReplyCallback(message);
                }
                else {
                    var payload: any;
                    message.getBinaryAttachment() && (payload = JSON.parse(SolaceService.CleanUpJson(message.getBinaryAttachment())));
                    this.invokeClientMethod(destination.getName(), payload);

                    if (message.getReplyTo()) {
                        var reply = solclientjs.SolclientFactory.createMessage();
                        this.session.sendReply(message, reply);
                    }
                }
            } catch (error) {
                Vue.Logger.LogError(error, false);
            }
        });

        var self = this;
        var connect = function () {
            try {
                self.session.connect();
            } catch (error) {
                Vue.Logger.LogError(error, false);
            }
        }

        var urlNoProto = url.split('/').slice(2).join('/'); // remove protocol prefix
        var iframe: HTMLIFrameElement = <HTMLIFrameElement>document.getElementById('solaceiframe');
        iframe.onload = connect;

        var src = 'http://' + urlNoProto + '/crossdomain.xml';
        if (window.location.protocol.includes('https')) {
            src = 'https://' + urlNoProto + '/crossdomain.xml';
        }
        iframe.src = src;
    }

    C_2_32 = Math.pow(2, 32);

    private leftPad(str, len) {
        return '0'.repeat(len - str.length) + str;
    }

    private generateRandomId() {
        var rand = (Math.random() * this.C_2_32).toFixed(0);
        return this.leftPad(rand.toString(), 10);
    }

    private GenerateClientName(name: string) {
        var platform = (Process as any).platform;
        var result = platform + "/" + (this.generateRandomId()) + "/" + name;
        return result;
    }

    private sessionStarted() {
        this.Started = true;
        this.sessionSubscribeAndUnsubscribeTopics();
        this.invokeClientMethod("sessionStarted");
    }

    private sessionStopped() {
        this.Started = false;
        this.invokeClientMethod("sessionStopped");
    }
    public static CleanUpJson(json) {
        //Messages over Solace that were published from HTTP have some random control characters at the start & end of the actual payload.
        var start = json.indexOf('{');
        var end = json.lastIndexOf('}');
        var clean = json.substring(start, end + 1);

        return clean.length > 0 ? clean : "{}";
    }

    private invokeClientMethod(methodName: string, ...args: any[]) {
        var self = this;
        let found = false;
        let methods = this.Contexts.topicMethods.get(methodName);
        if (methods) {
            methods.forEach((method, index) => {
                found = true;
                method.apply(this, args);
            });
        } else {
            this.Contexts.topicMethods.forEach((value, key, map) => {
                if (self.matchRule(methodName, key)) {
                    value.forEach((item, index) => {
                        found = true;
                        item.apply(this, args);
                    });
                }
            });
            if (!found)
                Vue.Logger.LogWarning(`No client method with the name '${methodName}' found.`);
        }
    }

    matchRule = function (str, rule) {
        return new RegExp("^" + rule.split("*").join(".*").replace('>', '.+') + "$").test(str);
    }


    idCounter: number = 0;
    private NextId(): number {
        return ++this.idCounter;
    }

    GetCorrelationId(): string {
        return this.NextId() + '';
    }

    async Invoke(topic: string, payload: any = null, replyTo: string = null, correlationId: string = null, isRequest: boolean = true): Promise<any> {
        let message: solclientjs.Message = this.createMessage(topic, payload, replyTo, correlationId);
        let p = new Promise<any>((resolve, reject) => {
            let self = this;
            if (isRequest) {
                this.Sender.sendRequest(this.session,
                    message,
                    30000,
                    function (session, message: solclientjs.Message) {
                        Vue.Logger.LogInfo(message);
                        let response = null;
                        message.getBinaryAttachment() && (response = JSON.parse(SolaceService.CleanUpJson(message.getBinaryAttachment())));
                        resolve(response);
                    },
                    function (session, event) {
                        Vue.Logger.LogInfo(event);
                        reject(event);
                    },
                    null
                );
            }
            else {
                this.session.send(message);
                resolve(void 0);
            }
        });
        return p;
    }

    createMessage(topic: string, payload: any, replyTo: any = null, correlationId: string = null): solclientjs.Message {
        var message: solclientjs.Message = solclientjs.SolclientFactory.createMessage();
        message.setDestination(solclientjs.SolclientFactory.createTopicDestination(topic));
        message.setDeliveryMode(solclientjs.MessageDeliveryModeType.DIRECT);
        message.setCorrelationId(this.id.toString());
        this.id++;
        let map: solclientjs.SDTMapContainer = new solclientjs.SDTMapContainer();

        if (payload) {
            message.setBinaryAttachment(JSON.stringify(payload));
            map.addField("Content-Type", solclientjs.SDTFieldType.STRING, "application/json");
            message.setUserPropertyMap(map);
        }

        if (replyTo) {
            message.setReplyTo(solclientjs.SolclientFactory.createTopicDestination(replyTo))
        }
        if (correlationId) {
            message.setCorrelationId(correlationId);
        }

        return message;
    }

    private sessionSubscribeAndUnsubscribeTopics() {
        this.Contexts.subscribedTopics.forEach((value, topic) => {
            if (value > 0)
                this.sessionSubscribe(topic);
            else
                this.sessionUnsubscribe(topic);
        });
    }

    private runMethodOrAddToSessionStarted(methods: Map<string, (...args: any[]) => void>) {
        methods.forEach((method, topic) => {
            if (topic == "") {
                if (this.Started) {
                    method();
                } else {
                    methods.set("sessionStarted", method);
                }
                methods.delete("");
            }
        });
    }

    On(methods: Map<string, (...args: any[]) => void>) {
        this.runMethodOrAddToSessionStarted(methods);

        var context = new Context(methods, false);
        this.Contexts.AddContext(context);

        return context;
    }

    Off(context: Context) {
        this.Contexts.RemoveContext(context);
    }

    Subscribe(methods: Map<string, (...args: any[]) => void>) {
        var context = new Context(methods);
        this.Contexts.AddContext(context);

        context.topics.forEach(topic => {
            context.subscribed = true;
            if (this.Started) {
                this.sessionSubscribe(topic);
            }
        });
        return context;
    }

    private sessionSubscribe(topic: string) {
        var sessionEventsDestination: solclientjs.Destination = solclientjs.SolclientFactory.createTopicDestination(topic);
        try {
            this.session.subscribe(
                sessionEventsDestination,
                true, // generate confirmation when subscription is added successfully
                { correlationKey: "CLIENT_SUBSCRIBE" }, // use topic name as correlation key
                10000 // 10 seconds timeout for this operation
            );
        }
        catch (error) {
            Vue.Logger.LogError(error);
        }
    }

    Unsubscribe(context: Context) {
        if (this.Contexts.RemoveContext(context) > -1) {
            context.topics.forEach(topic => {
                var count = this.Contexts.subscribedTopics.get(topic);
                if (this.Started && count <= 0) {
                    this.sessionUnsubscribe(topic);
                }
            });
        }
    }

    private sessionUnsubscribe(topic: string) {
        var sessionEventsDestination: solclientjs.Destination = solclientjs.SolclientFactory.createTopicDestination(topic);
        try {
            this.session.unsubscribe(
                sessionEventsDestination,
                true, // generate confirmation when subscription is added successfully
                { correlationKey: "CLIENT_SUBSCRIBE" }, // use topic name as correlation key
                10000 // 10 seconds timeout for this operation
            );
            this.Contexts.DeleteTopicFromSubscribedTopic(topic);
        } catch (error) {
            Vue.Logger.LogError(error);
        }
    }
}