import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import BootloaderModel from './bootloaderModel';

export default class Bootloader {
    bootloader_id: number;
    soft_device_id: string;
    major_version: string;
    minor_version: string;
    description: string;
    storage_uri: string;
    storage_sas_token: string;
    created_by: string;
    created_date: string;
    deleted: boolean;
    deleted_by: string;
    deleted_date: string;
    soft_device_name: string;
    models: Array<BootloaderModel>;
    has_deployments: boolean;

    get model_nos(): string {
        let modelNos = '';
        let firstRow = true;
        this.models.forEach(m => {
            if (firstRow) {
                modelNos += m.model_no;
                firstRow = false;
            }
            else {
                modelNos += ', ' + m.model_no;
            }
        })
        return modelNos;
    }

    get version(): string {
        return this.major_version + '.' + this.minor_version;
    }

    get sortversion(): number {
        if (this.version) {
            var e: number = 0;
            this.version.split('.').reverse().forEach(function (v, i) {
                return e += +v << (i * 8);
            });
            return e;
        }
    }

    constructor(bootloader: Bootloader = null) {
        if (bootloader) {
            this.bootloader_id = bootloader.bootloader_id;
            this.soft_device_id = bootloader.soft_device_id;
            this.major_version = bootloader.major_version;
            this.minor_version = bootloader.minor_version;
            this.description = bootloader.description || '';
            this.storage_uri = bootloader.storage_uri || '';
            this.storage_sas_token = bootloader.storage_sas_token;
            this.created_by = bootloader.created_by || '';
            this.created_date = bootloader.created_date && format(parseISO(bootloader.created_date), 'yyyy-MM-dd HH:mm') || '';
            this.deleted = bootloader.deleted || false;
            this.deleted_by = bootloader.deleted_by || '';
            this.deleted_date = bootloader.deleted_date && format(parseISO(bootloader.deleted_date), 'yyyy-MM-dd HH:mm') || '';
            this.soft_device_name = bootloader.soft_device_name || '';
            this.has_deployments = bootloader.has_deployments || false;
            this.models = [];

            bootloader.models.forEach(item => {
                this.models.push(new BootloaderModel(item, bootloader));
            });
        }
    }
}

export class ModuleBootloader {
    scheduled: string;
    installed: string;
    serial_id: string;
    bootloader_version: string;
}

