import { warn } from 'vue-paginate/src/util/debug'
import { ResizeSensor } from "css-element-queries";
export default {
    name: 'paginate',
    props: {
        name: {
            type: String,
            required: true
        },
        list: {
            type: Array,
            required: true
        },
        per: {
            type: Number,
            default: 3,
            validator(value) {
                return value > 0
            }
        },
        dynamicPer: {
            type: Boolean,
            default: false
        },
        tag: {
            type: String,
            default: 'ul'
        }
    },
    data() {
        return {
            initialListSize: this.list.length
        }
    },
    computed: {
        currentPage: {
            get() {
                if (this.$parent.paginate[this.name]) {
                    return this.$parent.paginate[this.name].page
                }
            },
            set(page) {
                this.$parent.paginate[this.name].page = page
            }
        },
        pageItemsCount() {
            const numOfItems = this.list.length
            const first = this.currentPage * this.per + 1
            const last = Math.min((this.currentPage * this.per) + this.per, numOfItems)
            return `${first}-${last} of ${numOfItems}`
        }
    },
    mounted() {
        if (this.per <= 0) {
            warn(`<paginate name="${this.name}"> 'per' prop can't be 0 or less.`, this.$parent)
        }
        if (!this.$parent.paginate[this.name]) {
            warn(`'${this.name}' is not registered in 'paginate' array.`, this.$parent)
            return
        }
        if (this.dynamicPer) {
            this.calculatePer();
            this.paginateList()
            this.$root.$on("resize", () => {
                this.calculatePer();
            });

            const self = this;
            const element = this.$el.parentElement.parentElement;
            new ResizeSensor(element, function (element) { self.calculatePer(); })
        } else {
            this.paginateList()
        }
    },
    activated() {
        if (this.dynamicPer) {
            this.calculatePer();
        }
    },
    watch: {
        currentPage() {
            this.paginateList()
        },
        list() {
            if (this.initialListSize !== this.list.length) {
                // On list change, refresh the paginated list only if list size has changed
                this.currentPage = 0
            }
            this.paginateList()

        },
        per() {
            this.currentPage = 0
            this.paginateList()
        }
    },
    methods: {
        paginateList() {
            const index = this.currentPage * this.per
            const paginatedList = this.list.slice(index, index + this.per)
            this.$parent.paginate[this.name].list = paginatedList
        },
        goToPage(page) {
            const maxPage = Math.ceil(this.list.length / this.per)
            if (page > maxPage) {
                warn(`You cannot go to page ${page}. The last page is ${maxPage}.`, this.$parent)
                return
            }
            this.currentPage = page - 1
        },
        calculatePer() {
            if (!this.dynamicPer)
                return this.per;

            const rowDefaultHeight = 29;
            const theadDefaultHeight = 28;

            const element = this.$el.parentElement.parentElement;
            let table = $(element).eq(0);
            let h = table.outerHeight() - 5;
            let trg = table.find('>table>tbody>tr').not(':hidden').not('.expanded-row');
            let tr = 0;
            trg.each(function (a) {
                tr += $(this).outerHeight();
            });
            //tr = Math.ceil(tr / trg.length) || rowDefaultHeight;
            let heights = trg.map(function (a) {
                return $(this).outerHeight();
            }).get();
            tr = heights.length && Math.max.apply(null, heights) || rowDefaultHeight;

            let th = table.find('thead').eq(0).outerHeight() || theadDefaultHeight;
            if (!(h && tr && th !== undefined)) {
                return 15;
            }

            let itemsPerPage = Math.floor((h - th) / tr);

            this.per = (itemsPerPage < 3 ? 3 : itemsPerPage);

        }
    },
    render(h) {
        return h(this.tag, {}, this.$slots.default)
    }
}
