import { SubLocationState } from '../types'
import SubLocation from '../../models/subLocation'
import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import ApiResponse from 'models/apiResponse'

//Get an SubLocation from a Vue component by calling this.$store.state.subLocation.list;

const state: SubLocationState = {
    list: [],
    timestampMS: null
}

const getters: GetterTree<SubLocationState, any> = {
    getSubLocationList: state => () => {
        return state.list;
    }
}

const mutations: MutationTree<SubLocationState> = {
    setSubLocationList(state, data) {
        //reset list first.
        state.list = [];
        data.forEach((subLocation: SubLocation, index) => {
            Vue.set(state.list, index, subLocation);
        });
    },
    setSubLocationTimestamp(state) {
        // 24 hours
        const expiration = 24 * 60 * 60 * 1000;
        state.timestampMS = new Date().getTime() + expiration;
    }
}

const actions: ActionTree<SubLocationState, any> = {
    async fetchSubLocations({ commit, state, dispatch }) {
        if (state.list.length == 0 || (state.timestampMS && new Date().getTime() > state.timestampMS)) {
            await dispatch('refreshSubLocations');
        }
    },
    async refreshSubLocations({ commit, state }) {
        const url = Vue.IdentityConfig.legacyApiUri + "/remote/subLocations";
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "no-cache"
        };
        await fetch(url, options)
            .then(response => response.json() as Promise<ApiResponse<SubLocation[]>>)
            .then(data => {
                if (data.result && data.result.length > 0) {
                    const subLocations = [];
                    data.result.forEach(f => {
                        subLocations.push(new SubLocation(f));
                    })
                    commit('setSubLocationList', subLocations);
                    commit('setSubLocationTimestamp');
                }
                if (data.errors) {
                    data.errors.forEach(e => Vue.Logger.LogError(e.message));
                }
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}