import { RemoteModalState } from '../types'
import { GetterTree, MutationTree } from 'vuex'

const state: RemoteModalState = {
    showModal: false
}

const getters: GetterTree<RemoteModalState, any> = {
    remoteModalState: state => state.showModal
}

const mutations: MutationTree<RemoteModalState> = {
    showRemoteModal(state, payload: boolean) {
        state.showModal = payload;
    }
}

export default {
    state,
    mutations,
    getters
}