import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import Bootloader from './bootloader';

export default class BootloaderModel {
    bootloader_model_id: number;
    bootloader_id: number;
    bootloader_status_id: BootloaderStatusCode;
    comment: string;
    created_by: string;
    created_date: string;

    major_version: number;
    minor_version: number;
    model_no: string;
    soft_device_name: string;
    description: string;
    history: BootloaderModel[] = Array<BootloaderModel>();
    showHistory: boolean = false;
    has_deployments: boolean;

    get bootloader_status() {
        return BootloaderStatusCode[this.bootloader_status_id];
    }

    get version(): string {
        return this.major_version + '.' + this.minor_version;
    }

    constructor(bootloaderModel: BootloaderModel = null, bootloader: Bootloader = null) {
        if (bootloader) {
            this.bootloader_model_id = bootloaderModel.bootloader_model_id;
            this.bootloader_id = bootloaderModel.bootloader_id;
            this.bootloader_status_id = bootloaderModel.bootloader_status_id;
            this.major_version = bootloaderModel.major_version || (bootloader && +bootloader.major_version);
            this.minor_version = bootloaderModel.minor_version || (bootloader && +bootloader.minor_version);
            this.created_by = bootloaderModel.created_by || '';
            this.description = bootloaderModel.description || (bootloader && bootloader.description) || '';
            this.created_date = format(parseISO(bootloaderModel.created_date), 'yyyy-MM-dd HH:mm');
            this.comment = bootloaderModel.comment || '';
            this.model_no = bootloaderModel.model_no || '';
            this.soft_device_name = bootloaderModel.soft_device_name || '';
            this.has_deployments = bootloaderModel.has_deployments || (bootloader && bootloader.has_deployments);

            if (bootloaderModel.history)
                bootloaderModel.history.forEach((item) => {
                    this.history.push(new BootloaderModel(item));
                });
        }
    }

    get sortversion(): number {
        if (this.version) {
            var e: number = 0;
            this.version.split('.').reverse().forEach(function (v, i) {
                return e += +v << (i * 8);
            });
            return e;
        }
    }
}

export enum BootloaderStatusCode {
    "" = 0,
    "New" = 1,
    "Test Passed" = 2,
    "Test Failed" = 3,
    "Approved" = 4,
    "Retired" = 5
}